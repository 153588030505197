import { Button, notification } from 'antd';
import Table from '../../../../Template/layoutComponents/Table';

import { PlusOutlined } from '@ant-design/icons';

import './style.css';
import { getInstruments, newInstrument } from '../../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
import useTemplateState from '../../../../Template/atoms/variables';
import useMaintenanceState from '../../../atoms/variables';
import { useEffect, useState } from 'react';
import { getInstrumentCategories } from '../../../../Settings/helpers/apiCalls';
import { ignore } from 'antd/es/theme/useToken';
import TableTip from '../../../../Template/layoutComponents/Table/components/tip';
import { parseIsoDateString } from '../../../../helpers/misc';
import RenderBool from '../../../../Template/layoutComponents/Table/components/boolValues';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Instruments = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { instance } = useMsal();

  const { setPendingApiCalls } = useTemplateState();
  const { instruments, setInstruments, orgs, setViewDetailsItem } = useMaintenanceState();
  const [notificationApi, contextHolder] = notification.useNotification();

  const [showDetailsButton, setShowDetailsButton] = useState(false);
  const [allCategories, setAllCategories] = useState([]);

  const [selectedInstruments, setSelectedInstruments] = useState([]);

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) =>
        current.concat(['getInstruments', 'getInstrumentCategories']),
      );

      const responses = await Promise.all([
        getInstruments(instance, { limit: 10 }),
        getInstrumentCategories(instance),
      ]);
      const instrumentsResponse = responses[0];
      setInstruments(instrumentsResponse);
      setAllCategories(responses[1]);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) =>
        current.filter((item) => item !== 'getInstruments' && item !== 'getInstrumentCategories'),
      );
    }
  };

  return (
    <div className='instrumentsContent'>
      {contextHolder}
      <div className='instrumentsContentHeader'>
        <div className='pageTitle'>{t('instruments_title')}</div>
        <div className='pageButtons'>
          {showDetailsButton && (
            <Button
              className='newItemButton'
              size='large'
              style={{
                borderColor: 'black',
                background: 'white',
                color: 'black',
              }}
              onClick={async () => {}}
            >
              {t('view_details')}
            </Button>
          )}
          <Button
            className='newItemButton'
            size='large'
            icon={<PlusOutlined />}
            style={{
              borderColor: '#1777FF',
              background: '#1777FF',
              color: 'white',
            }}
            onClick={async () => {
              setViewDetailsItem({
                id: 'newInstrument',
                name: t('new_instrument'),
                category: null,
                properties: [
                  {
                    label: t('name'),
                    type: 'text',
                    value: t('new_instrument'),
                    main: true,
                    required: true,
                    key: 'name',
                  },
                  {
                    label: t('category'),
                    type: 'singleSelect',
                    value: null,
                    options: [],
                    main: true,
                    allowEdit: true,
                    required: true,
                    key: 'category',
                  },
                  {
                    label: t('organization'),
                    type: 'singleSelect',
                    value: null,
                    options: [],
                    main: true,
                    allowEdit: true,
                    required: true,
                    key: 'organization',
                  },
                  {
                    label: t('department'),
                    type: 'singleSelect',
                    value: null,
                    options: [],
                    main: true,
                    allowEdit: true,
                    required: true,
                    key: 'department',
                  },
                  {
                    label: t('cost_center'),
                    type: 'singleSelect',
                    value: null,
                    options: [],
                    main: true,
                    allowEdit: true,
                    required: true,
                    key: 'cost_center',
                  },
                  {
                    label: t('manufacturer'),
                    type: 'singleSelect',
                    value: null,
                    options: [],
                    main: true,
                    allowEdit: true,
                    required: true,
                    key: 'manufacturer',
                  },
                  {
                    label: t('model_number'),
                    type: 'text',
                    value: null,
                    main: true,
                    required: true,
                    key: 'model',
                  },
                  {
                    label: t('serial_number'),
                    type: 'text',
                    value: null,
                    main: true,
                    required: true,
                    key: 'serial_number',
                  },

                  {
                    label: t('purchase_date'),
                    type: 'date',
                    value: null,
                    main: true,
                    required: true,
                    key: 'purchase_date',
                  },
                  {
                    label: t('certification_date'),
                    type: 'date',
                    value: null,
                    main: true,
                    disabled: true,
                    required: false,
                    key: 'certification_date',
                  },
                  {
                    label: t('out_of_service'),
                    type: 'bool',
                    value: false,
                    main: true,
                    allowEdit: true,
                    required: true,
                    key: 'out_of_service',
                  },
                ],
              });
              navigate('/maintenance/instruments/newInstrument');
            }}
          >
            {t('new_instrument')}
          </Button>
        </div>
      </div>
      <Table
        handleSelect={(selected) => {
          setSelectedInstruments(selected);
          // if (selected.length === 1) {
          //   setShowDetailsButton(true);
          // } else {
          //   setShowDetailsButton(false);
          // }
        }}
        handleRowClick={(row) => {
          setViewDetailsItem({
            id: row.id,
            name: row.name,
            category: row.category,
            properties: row.properties.map((prop) => ({
              ...prop,
              disabled: prop.allowEdit ? false : prop.main,
            })),
          });
          navigate(`/maintenance/instruments/${row.id}`);
        }}
        apiCall={getInstruments}
        items={instruments?.items}
        startingTotalItems={instruments.totalItems ?? 0}
        tableSettings={{
          columnsConfig: {
            category: {
              label: t('category'),
              replaceValue: (value) =>
                allCategories.find((cat) => cat._id === value || cat.id === value),
            },
            name: {
              label: t('name'),
            },
            organization: {
              label: t('organization'),
              replaceValue: (value) => orgs.find((org) => org._id === value || org.id === value),
            },
            serial_number: {
              label: t('serial_number'),
            },
            certification_date: {
              label: t('certification_date'),
              replaceValue: (value) => {
                return {
                  label: parseIsoDateString(new Date(value).toISOString()),
                  id: value,
                };
              },
            },
            out_of_service: {
              label: t('out_of_service'),
              customElement: (item) => <RenderBool children={item ? item.out_of_service : false} />,
            },
            status: {
              label: t('status'),
              customElement: (item) => <TableTip children={item.status ?? item} />,
            },
          },
          sortable: [
            'category',
            'name',
            'organization',
            'serial_number',
            'certification_date',
            'out_of_service',
            'status',
          ],
          listFilter: [
            'category',
            'status',
            'organization',
            'out_of_service',
            'certification_date',
          ],
          searchFilter: ['name', 'serial_number'],
          uniqueValues: instruments.uniqueValues,
        }}
      />
    </div>
  );
};

export default Instruments;
