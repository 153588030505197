import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import useTemplateState from '../../../../../../../../../Template/atoms/variables';
import { useMsal } from '@azure/msal-react';
import {
  getSystemById,
  getInterconnectionAndUniques,
} from '../../../../../../../../helpers/apiCalls';
import { useEffect, useState } from 'react';
import Table from '../../../../../../../../../Template/layoutComponents/Table';
import TableTip from '../../../../../../../../../Template/layoutComponents/Table/components/tip';

import './style.css';
import { useNavigate, useParams } from 'react-router-dom';
import useMaintenanceState from '../../../../../../../../atoms/variables';

const SystemTable = ({ system, addSystemItems }) => {
  const [progress, stepProgress] = useState(0);
  const { instance } = useMsal();
  const navigate = useNavigate();
  const { plantId } = useParams();

  const [notificationApi, contextHolder] = notification.useNotification();
  const { setPendingApiCalls } = useTemplateState();
  const { t } = useTranslation();

  const { setViewDetailsItem } = useMaintenanceState();

  const [systemDetails, setSystemDetails] = useState(null);
  const [systemInterconnections, setSystemInterconnections] = useState({});

  useEffect(() => {
    triggerApiCalls();
  }, []);

  useEffect(() => {
    stepProgress(
      (systemInterconnections?.items?.filter((item) => item?.status?.toLowerCase() === 'approved')
        ?.length /
        systemInterconnections?.items?.length) *
        100,
    );
  }, [systemInterconnections]);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) =>
        current.concat(['getInterconnectionAndUniques', 'getSystemById']),
      );

      const responses = await Promise.all([
        getInterconnectionAndUniques(instance, {
          system: system,
          limit: 10,
        }),
        getSystemById(instance, system),
      ]);

      addSystemItems(responses[0].items);

      setSystemInterconnections(responses[0]);
      setSystemDetails(responses[1]);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) =>
        current.filter(
          (item) => item !== 'getInterconnectionAndUniques' && item !== 'getSystemById',
        ),
      );
    }
  };

  return (
    <div className='systemTable'>
      {contextHolder}
      <div className='systemDependencyHeader'>
        <div className='systemNameContainer'>
          <div className='systemName'>{systemDetails?.name}</div>
          <div className='systemNavigate'></div>
        </div>
        <div className='systemProgressContainer'>
          <div className='systemProgressCircle'>
            <div className='progress-container'>
              <div className='hollow-circle'></div>
              <div
                className='progress-circle'
                style={{
                  background: `conic-gradient(#1777FF ${progress ?? 0}%, #0000000F 0%)`,
                }}
              ></div>
            </div>
          </div>
          <div className='systemProgressInfo'>{`${systemInterconnections?.items?.filter((item) => item?.status?.toLowerCase() === 'approved')?.length ?? 0}/${systemInterconnections?.items?.length ?? 0} ${t('completed')}`}</div>
        </div>
      </div>
      <Table
        handleSelect={null}
        handleRowClick={(row) => {
          setViewDetailsItem({ ...row, type: undefined });
          navigate(`/maintenance/plants/${plantId}/interconnections/${row.id}`);
        }}
        apiCall={(instance, queryParams) =>
          getInterconnectionAndUniques(instance, {
            system: system,
            ...queryParams,
          })
        }
        items={systemInterconnections.items}
        startingTotalItems={systemInterconnections.totalItems ?? 0}
        tableSettings={{
          columnsConfig: {
            name: {
              label: t('system_interconnections'),
            },
            status: {
              label: t('status'),
              customElement: (item) => <TableTip children={item.status ?? item} />,
            },
          },
          sortable: ['name', 'status'],
          listFilter: ['status'],
          searchFilter: ['name'],
          uniqueValues: systemInterconnections.uniqueValues,
        }}
      />
    </div>
  );
};

export default SystemTable;
