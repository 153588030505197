import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

import axios from 'axios';

import { msalConfig } from './config.js';
const apiUrl = `${process.env.REACT_APP_BFF_API}`;

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGOUT_SUCCESS)
  ) {
    logout();
  }
});

const isB2C = process.env.REACT_APP_MSAL_IS_B2C == 'true';
export const acquireToken = async (instance, scope, account = null) => {
  try {
    const response = await instance.acquireTokenSilent({
      scopes: [scope],
      account: account || instance.getActiveAccount(),
    });
    return isB2C ? response.accessToken : response.idToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return instance.acquireTokenRedirect({
        scopes: [scope],
      });
    } else {
      console.error(error);
      throw error;
    }
  }
};

export async function logout() {
  try {
    const { data } = await axios.post(`${apiUrl.replace('/api', '')}/logout`, {});
    console.log('logout', data);
  } catch (e) {
    throw e;
  }
}