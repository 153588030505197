import { Button, notification, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import Table from '../../../../Template/layoutComponents/Table';
import TableTip from '../../../../Template/layoutComponents/Table/components/tip';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  ExportOutlined,
  FilterOutlined,
} from '@ant-design/icons';

import './style.css';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useTemplateState from '../../../../Template/atoms/variables';
import {
  getReportsInfo,
  getStageTasks,
  signTasks,
  downloadTasksExport,
} from '../../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
import ActionCell from '../Details/components/CommissioningTab/CriteriaTypes/TaskReports/components/ActionsCell';
import ReportPage from '../Details/components/CommissioningTab/CriteriaTypes/TaskReports/components/ReportPage';
import i18n from '../../../../_shared/translate/translate-config';
import { parseIsoDateString } from '../../../../helpers/misc';
import AdvancedFiltersModal from './components/AdvancedFiltersModal';
import TaskReportService from '../../../../services/TaskReportService';
import ReportTranslationService from '../../../../services/ReportTranslationService';
import useMaintenanceState from '../../../atoms/variables';

const Reports = () => {
  const { t } = useTranslation();
  const { plantId } = useParams();
  const { instance } = useMsal();
  const navigate = useNavigate();

  const [notificationApi, contextHolder] = notification.useNotification();

  const { setViewDetailsItem } = useMaintenanceState();

  const {
    setPendingApiCalls,
    user,
    setModalOpen,
    setModalContent,
    setModalStyle,
    setModalTitle,
    setModalConfirm,
    setCleanOnCancel,
    companyInfo,
  } = useTemplateState();

  const [reports, setReports] = useState(null);
  const [selectedReports, setSelectedReports] = useState([]);
  const [showButton, setShowButton] = useState(false);

  const [reportInfos, setReportInfos] = useState({
    executed: 0,
    approved: 0,
    pending: 0,
    failed: 0,
  });
  const [cards, setCards] = useState([
    {
      key: 'executed',
      title: t('tasks_executed'),
      value: reportInfos['executed'],
      filter: {},
    },
    {
      key: 'approved',
      title: t('approved_reports'),
      value: reportInfos['approved'],
      filter: {
        'report.status': 'Approved',
      },
    },
    {
      key: 'pending',
      title: t('pending_approval'),
      value: reportInfos['pending'],
      filter: {
        status: 'Validated',
        'report.status[in]': ['Pending Approval', 'Ongoing approval'],
        'report.signatures[elemMatch]': {
          anchor: { in: user.access ?? [] },
          approve: { exists: false },
        },
      },
    },
    {
      key: 'failed',
      title: t('failed_rejected'),
      value: reportInfos['failed'],
      filter: {
        or: [{ status: 'Fail' }, { 'report.status': 'Rejected' }],
      },
    },
  ]);

  const [updatingCards, setUpdatingCards] = useState(false);
  const [updatingTable, setUpdatingTable] = useState(false);

  const [reportOpen, setReportOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  const [selectedCard, setSelectedCard] = useState({
    key: 'executed',
    title: t('tasks_executed'),
    value: reportInfos['executed'],
    filter: {},
  });
  const [finalFilter, setFinalFilter] = useState({ 'subject.location': plantId });
  const [advancedFilters, setAdvancedFilters] = useState({});
  const [tableFilters, setTableFilters] = useState({});

  const [forceReload, setForceReload] = useState(false);

  useEffect(() => {
    if (user) triggerApiCalls();
  }, [user]);

  useEffect(() => {
    setFinalFilter({
      ...advancedFilters,
      ...selectedCard?.filter,
      'subject.location': plantId,
    });
  }, [advancedFilters, selectedCard]);

  useEffect(() => {
    updateReportsInfo();
  }, [advancedFilters, tableFilters]);

  useEffect(() => {
    setSelectedReports([]);
    setForceReload(true);
  }, [finalFilter]);

  useEffect(() => {
    setCards([
      {
        key: 'executed',
        title: t('tasks_executed'),
        value: reportInfos['executed'],
        filter: {},
      },
      {
        key: 'approved',
        title: t('approved_reports'),
        value: reportInfos['approved'],
        filter: {
          'report.status': 'Approved',
        },
      },
      {
        key: 'pending',
        title: t('pending_approval'),
        value: reportInfos['pending'],
        filter: {
          status: 'Validated',
          'report.status[in]': ['Pending Approval', 'Ongoing approval'],
          'report.signatures[elemMatch]': {
            anchor: { in: user.access ?? [] },
            approve: { exists: false },
          },
        },
      },
      {
        key: 'failed',
        title: t('failed_rejected'),
        value: reportInfos['failed'],
        filter: {
          or: [{ status: 'Fail' }, { 'report.status': 'Rejected' }],
        },
      },
    ]);
  }, [user, reports, reportInfos, t]);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getStageTasks']));

      const responses = await Promise.all([
        getStageTasks(instance, { ...finalFilter, limit: 10, uniqueValues: true }),
        getReportsInfo(instance, { access: user?.access, location: plantId }),
      ]);

      setReports(responses[0]);
      setReportInfos(responses[1]);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) => current?.filter((item) => item !== 'getStageTasks'));
    }
  };

  const updateReportsInfo = async () => {
    try {
      setUpdatingCards(true);

      const fixedTableFilters = Object.entries(tableFilters).reduce((acc, [key, value]) => {
        if (key.includes('[regex]')) acc[key.replace('[regex]', 'REGEX')] = value;
        acc[key] = value;
        return acc;
      }, {});

      const fixedAdvancedFilters = Object.entries(advancedFilters).reduce((acc, [key, value]) => {
        if (key.includes('[gte]')) acc[key.replace('[gte]', 'GTE')] = value;
        if (key.includes('[lte]')) acc[key.replace('[lte]', 'LTE')] = value;

        acc[key] = value;

        return acc;
      }, {});

      const response = await getReportsInfo(instance, {
        access: user?.access,
        location: plantId,
        filter: {
          ...fixedAdvancedFilters,
          ...fixedTableFilters,
          page: undefined,
        },
      });

      setReportInfos(response);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setUpdatingCards(false);
    }
  };

  const openModal = () => {
    setModalTitle(t('advanced_filters'));
    setModalContent(
      <AdvancedFiltersModal
        advancedFilters={advancedFilters}
        setAdvancedFilters={setAdvancedFilters}
      />,
    );

    setModalStyle({
      padding: '3rem',
      gap: '1rem',
      height: 'unset',
      width: '80rem',
      maxWidth: 'unset',
    });
    setCleanOnCancel(true);
    setModalConfirm(null);

    setModalOpen(true);
  };

  const handleSelection = useCallback((selected) => {
    setSelectedReports((prev) => {
      const hasChanged = JSON.stringify(prev) !== JSON.stringify(selected);

      return hasChanged ? selected : prev; // Evita atualização se não houver mudanças
    });

    setShowButton(selected.length > 0);
  }, []);

  const handleBulkDownload = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['downloadReports']));
      let translatedReports = selectedReports.map((report) =>
        ReportTranslationService.translateReport(report, i18n.language),
      );
      await TaskReportService.build(translatedReports, companyInfo?.logo, true);
      setSelectedReports([]);
      setForceReload(true);
    } catch (error) {
      console.error('Error generating PDFs:', error);
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'downloadReports'));
    }
  };
  const handleApproveTasks = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['approveTasks']));
      let ids = selectedReports.map((t) => t._id);
      let signedTasks = await signTasks(instance, ids);

      setReports((current) => ({
        ...current,
        items: current.items.map((task) =>
          signedTasks.some((signedTask) => signedTask._id === task._id)
            ? signedTasks.find((signedTask) => signedTask._id === task._id)
            : task,
        ),
      }));
      setSelectedReports([]);
      setForceReload(true);
    } catch (error) {
      console.error('Error approving tasks:', error);
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'approveTasks'));
    }
  };
  const handleRejectTasks = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['rejectTasks']));
      let ids = selectedReports.map((t) => t._id);
      let signedTasks = await signTasks(instance, ids, 'reject');

      setReports((current) => ({
        ...current,
        items: current.items.map((task) =>
          signedTasks.some((signedTask) => signedTask._id === task._id)
            ? signedTasks.find((signedTask) => signedTask._id === task._id)
            : task,
        ),
      }));
      setSelectedReports([]);
      setForceReload(true);
    } catch (error) {
      console.error('Error rejecting tasks:', error);
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'rejectTasks'));
    }
  };
  const handleExportTasks = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['exportTasks']));
      await downloadTasksExport(instance, plantId, { ...tableFilters, ...finalFilter });
    } catch (error) {
      console.error('Error exporting tasks:', error);
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'exportTasks'));
    }
  };

  const handleDownloadAll = async (totalItems) => {
    try {
      setPendingApiCalls((current) => current.concat(['downloadAll']));

      const getBatchSize = 100;

      if (totalItems === 0) {
        notificationApi.info({
          message: t('select_reports_to_download'),
          duration: 5,
        });
      }

      for (let i = 0; i < totalItems; i += getBatchSize) {
        const filteredReports = await getStageTasks(instance, {
          ...finalFilter,
          ...tableFilters,
          limit: getBatchSize,
          page: Math.ceil(i / getBatchSize) + 1,
        });

        const nonPendingReports = filteredReports.items.filter(
          (task) => !['pending', 'expired'].includes(task?.status?.toLowerCase()),
        );

        if (filteredReports.items.length === 0) {
          notificationApi.info({
            message: t('select_reports_to_download'),
            duration: 5,
          });
        } else if (nonPendingReports.length === 0) {
          notificationApi.info({
            message: t('pending_tasks_cannot_be_downloaded'),
            duration: 5,
          });
        } else {
          const translatedReports = nonPendingReports.map((report) =>
            ReportTranslationService.translateReport(report, i18n.language),
          );
          await TaskReportService.build(translatedReports, companyInfo?.logo, true);
        }
      }
      setSelectedReports([]);
      if (totalItems > 0) setForceReload(true);
    } catch (error) {
      console.error('Error exporting tasks:', error);
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'downloadAll'));
    }
  };

  const getDownloadTooltip = () => {
    if (!showButton) return t('select_reports_to_download');
    if (selectedReports.some((task) => task.status === 'Pending' || task.status === 'Expired'))
      return t('pending_tasks_cannot_be_downloaded');
    return '';
  };
  const getDownloadAllTooltip = () => {
    return t('pending_tasks_wont_be_downloaded');
  };
  const getApproveTooltip = () => {
    if (!showButton) return t('select_reports_to_approve');
    if (
      selectedReports.some(
        (task) =>
          task?.report?.status?.toLowerCase() === 'approved' ||
          task?.report?.status?.toLowerCase() === 'rejected',
      )
    )
      return t('some_reports_are_final');

    if (
      selectedReports.some(
        (task) =>
          task?.status?.toLowerCase() === 'pending' || task?.status?.toLowerCase() === 'expired',
      )
    )
      return t('some_tasks_are_pending');

    return '';
  };
  const getRejectTooltip = () => {
    if (!showButton) return t('select_reports_to_repprove');
    if (
      selectedReports.some(
        (task) =>
          task?.report?.status?.toLowerCase() === 'approved' ||
          task?.report?.status?.toLowerCase() === 'rejected',
      )
    )
      return t('some_reports_are_final');

    if (
      selectedReports.some(
        (task) =>
          task?.status?.toLowerCase() === 'pending' || task?.status?.toLowerCase() === 'expired',
      )
    )
      return t('some_tasks_are_pending');

    return '';
  };

  return (
    <>
      {contextHolder}
      {reportOpen && (
        <ReportPage
          setReportOpen={setReportOpen}
          report={selectedReport}
          setReport={setSelectedReport}
          setForceReload={setForceReload}
        />
      )}
      <div id='reports'>
        <div className='pageContentBlock'>
          <div className='pageContentHeader'>
            <div className='pageTitle'>{`${t('reports_overview')}`}</div>
            <div className='headerButtons'>
              {Object.keys(advancedFilters).length > 0 && (
                <Button
                  size='large'
                  icon={<CloseCircleOutlined />}
                  disabled={updatingCards || updatingTable}
                  style={{
                    borderColor: '#242424',
                    background: 'white',
                    color: '#242424',
                    opacity: updatingCards || updatingTable ? 0.5 : 1,
                  }}
                  onClick={() => {
                    setAdvancedFilters({});
                  }}
                >
                  {t('clean_advanced_filters')}
                </Button>
              )}
              <Button
                size='large'
                icon={<FilterOutlined />}
                disabled={updatingCards || updatingTable}
                style={
                  Object.keys(advancedFilters).length > 0
                    ? {
                        borderColor: '#1777FF',
                        background: '#1777FF',
                        color: 'white',
                        opacity: updatingCards || updatingTable ? 0.5 : 1,
                      }
                    : {
                        borderColor: '#1777FF',
                        background: 'white',
                        color: '#1777FF',
                        opacity: updatingCards || updatingTable ? 0.5 : 1,
                      }
                }
                onClick={() => {
                  openModal();
                }}
              >
                {t('advanced_filters')}
              </Button>
              <Tooltip title={getDownloadAllTooltip()}>
                <Button
                  size='large'
                  icon={<DownloadOutlined />}
                  style={{
                    borderColor: '#242424',
                    color: '#242424',
                    background: 'white',
                    opacity: updatingCards || updatingTable ? 0.5 : 1,
                  }}
                  disabled={updatingCards || updatingTable}
                  onClick={() =>
                    handleDownloadAll(
                      selectedCard.value > 0
                        ? selectedCard.value
                        : cards.find((c) => c.key === selectedCard.key)?.value,
                    )
                  }
                >
                  {t('download_all')}
                </Button>
              </Tooltip>
              <Button
                size='large'
                icon={<ExportOutlined />}
                style={{
                  borderColor: '#242424',
                  background: '#242424',
                  color: 'white',
                  opacity: updatingCards || updatingTable ? 0.5 : 1,
                }}
                disabled={updatingCards || updatingTable}
                onClick={handleExportTasks}
              >
                {t('export')}
              </Button>
            </div>
          </div>
          <div className='blockContent'>
            <div className='cards'>
              <div className='doubleCard'>
                {cards.slice(0, 2).map((card) => (
                  <div
                    className={`card ${updatingCards || updatingTable ? 'disabled' : ''} ${selectedCard?.key === card.key ? 'selected' : ''}`}
                    onClick={() => {
                      if (!updatingCards && !updatingTable) setSelectedCard(card);
                    }}
                  >
                    <div className={`cardHeader`}>{card.title}</div>
                    <div className='cardBody'>{card.value}</div>
                  </div>
                ))}
              </div>
              <div className='doubleCard'>
                {cards.slice(2, 4).map((card) => (
                  <div
                    className={`card ${updatingCards || updatingTable ? 'disabled' : ''} ${selectedCard?.key === card.key ? 'selected' : ''}`}
                    onClick={() => {
                      if (!updatingCards && !updatingTable) setSelectedCard(card);
                    }}
                  >
                    <div className={`cardHeader`}>{card.title}</div>
                    <div className='cardBody'>{card.value}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className='pageContentBlock' style={{ gap: '1rem' }}>
          <div className='pageContentHeader'>
            <div className='pageTitle'>{t('reports')}</div>
            <div className='headerButtons'>
              <Tooltip title={getDownloadTooltip()}>
                <Button
                  disabled={
                    !showButton ||
                    selectedReports.length === 0 ||
                    selectedReports.some((task) => task.status === 'Pending') ||
                    selectedReports.some((task) => task.status === 'Expired')
                  }
                  size='large'
                  style={{
                    borderColor: '#1777FF',
                    background: '#1777FF',
                    color: 'white',
                    opacity:
                      showButton &&
                      selectedReports.length !== 0 &&
                      !selectedReports.some((task) => task.status === 'Pending') &&
                      !selectedReports.some((task) => task.status === 'Expired')
                        ? 1
                        : 0.5,
                  }}
                  onClick={() => handleBulkDownload(selectedReports)}
                  icon={<DownloadOutlined />}
                >
                  {t('download')}
                </Button>
              </Tooltip>
              <Tooltip title={getApproveTooltip()}>
                <Button
                  disabled={
                    !showButton ||
                    selectedReports.length === 0 ||
                    selectedReports.some((task) => task?.report?.status?.toLowerCase() === 'n/a') ||
                    selectedReports.some(
                      (task) => task?.report?.status?.toLowerCase() === 'approved',
                    ) ||
                    selectedReports.some(
                      (task) => task?.report?.status?.toLowerCase() === 'rejected',
                    ) ||
                    selectedReports.some((task) => task?.status?.toLowerCase() === 'pending')
                  }
                  size='large'
                  style={{
                    borderColor: '#52C41A',
                    background: '#52C41A',
                    color: 'white',
                    opacity:
                      showButton &&
                      selectedReports.length !== 0 &&
                      !selectedReports.some(
                        (task) => task?.report?.status?.toLowerCase() === 'n/a',
                      ) &&
                      !selectedReports.some(
                        (task) => task?.report?.status?.toLowerCase() === 'approved',
                      ) &&
                      !selectedReports.some(
                        (task) => task?.report?.status?.toLowerCase() === 'rejected',
                      ) &&
                      !selectedReports.some((task) => task?.status?.toLowerCase() === 'pending')
                        ? 1
                        : 0.5,
                  }}
                  icon={<CheckCircleOutlined />}
                  onClick={() => handleApproveTasks(selectedReports)}
                >
                  {t('approve')}
                </Button>
              </Tooltip>
              <Tooltip title={getRejectTooltip()}>
                <Button
                  disabled={
                    !showButton ||
                    selectedReports.length === 0 ||
                    selectedReports.some((task) => task?.report?.status?.toLowerCase() === 'n/a') ||
                    selectedReports.some(
                      (task) => task?.report?.status?.toLowerCase() === 'approved',
                    ) ||
                    selectedReports.some(
                      (task) => task?.report?.status?.toLowerCase() === 'rejected',
                    ) ||
                    selectedReports.some((task) => task?.status?.toLowerCase() === 'pending')
                  }
                  size='large'
                  style={{
                    borderColor: '#FF4D4F',
                    background: '#FF4D4F',
                    color: 'white',
                    opacity:
                      showButton &&
                      selectedReports.length !== 0 &&
                      !selectedReports.some(
                        (task) => task?.report?.status?.toLowerCase() === 'n/a',
                      ) &&
                      !selectedReports.some(
                        (task) => task?.report?.status?.toLowerCase() === 'approved',
                      ) &&
                      !selectedReports.some(
                        (task) => task?.report?.status?.toLowerCase() === 'rejected',
                      ) &&
                      !selectedReports.some((task) => task?.status?.toLowerCase() === 'pending')
                        ? 1
                        : 0.5,
                  }}
                  icon={<CloseCircleOutlined />}
                  onClick={() => handleRejectTasks(selectedReports)}
                >
                  {t('reject')}
                </Button>
              </Tooltip>
            </div>
          </div>
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <Table
              ignoreExternalItemsChange={true}
              forceReload={forceReload}
              setForceReload={setForceReload}
              handleSelect={handleSelection}
              externalSelected={selectedReports}
              handleRowClick={(item) => {
                setViewDetailsItem(null);

                if (item?.subject?._type?.toLowerCase().includes('interconnection')) {
                  navigate(
                    `/maintenance/plants/${plantId}/interconnections/${item.subject?._id ?? item.subject?.id}`,
                  );
                }

                if (item?.subject?._type?.toLowerCase().includes('asset')) {
                  navigate(
                    `/maintenance/plants/${plantId}/assets/${item.subject?._id ?? item.subject?.id}`,
                  );
                }
              }}
              apiCall={async (instance, queryParams) => {
                setTableFilters(queryParams);
                setUpdatingTable(true);

                const response = await getStageTasks(instance, {
                  ...queryParams,
                  'report.status':
                    selectedCard.key === 'pending' || selectedCard.key === 'approved'
                      ? undefined
                      : queryParams['report.status'],
                  ...finalFilter,
                  uniqueValues: true,
                });
                setUpdatingTable(false);

                return {
                  ...response,
                  items: response.items.map((i) => ({
                    ...i,
                    'subject.name': i?.subject?.name,
                    updatedAt: i?.updatedAt,
                    'report.signatures': i?.report?.signatures?.some((signature) => {
                      return user?.access?.includes(signature?.anchor) && signature?.approve;
                    }),
                    'report.filename': i?.report?.filename,
                    'report.status': i?.report?.status,
                    action: 'placeholder',
                  })),
                };
              }}
              items={reports?.items.map((i) => ({
                ...i,
                'subject.name': i?.subject?.name,
                updatedAt: i?.updatedAt,
                'report.signatures': i?.report?.signatures?.some((signature) => {
                  return user?.access?.includes(signature?.anchor) && signature?.approve;
                }),
                'report.filename': i?.report?.filename,
                'report.status': i?.report?.status,
                action: 'placeholder',
              }))}
              disableFilters={
                selectedCard.key === 'approved'
                  ? ['report.status']
                  : selectedCard.key === 'pending'
                    ? ['status', 'report.status']
                    : []
              }
              startingTotalItems={reports?.totalItems ?? 0}
              tableSettings={{
                columnsConfig: {
                  'subject.name': {
                    label: t('name'),
                  },
                  test: {
                    label: t('task'),
                    customElement: (item) => {
                      return item?.test?.[i18n.language]?.title;
                    },
                  },
                  updatedAt: {
                    label: t('date'),
                    customElement: (item) => {
                      return parseIsoDateString(item?.['updatedAt']);
                    },
                  },
                  status: {
                    label: t('status'),
                    customElement: (item) => <TableTip children={item['status'] ?? item} />,
                  },
                  'report.filename': {
                    label: t('report'),
                  },
                  'report.status': {
                    label: t('report_status'),
                    customElement: (item) => <TableTip children={item['report.status'] ?? item} />,
                  },
                  'report.signatures': {
                    label: t('signed'),
                    customElement: (item) => (
                      <TableTip
                        children={item?.['report.signatures'] ? t('yes') : (t('no') ?? item)}
                      />
                    ),
                  },
                  action: {
                    label: t('action'),
                    customElement: (item) => {
                      return (
                        <ActionCell
                          item={item}
                          reportOpen={reportOpen}
                          setReportOpen={setReportOpen}
                          setSelectedReport={setSelectedReport}
                        />
                      );
                    },
                  },
                },
                sortable: [],
                listFilter: ['status', 'report.status'],
                searchFilter: ['subject.name', 'report.filename'],
                uniqueValues: reports?.uniqueValues,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
