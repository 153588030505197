import React, { useEffect, useState } from 'react';
import Table from '../../../../../../../../Template/layoutComponents/Table';
import { notification, Button } from 'antd';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { deleteFile, getFiles, saveFile } from '../../../../../../../helpers/files';
import useTemplateState from '../../../../../../../../Template/atoms/variables';
import { getTableConfig } from '../../../helpers/tableConfigHelper';
import moment from 'moment';

const DocumentUpload = ({
  asset,
  phaseTitle,
  stage,
  stageIndex,
  type,
  setStepProgress,
  testId,
  setAllTasks,
  isLocked,
}) => {
  const { setPendingApiCalls } = useTemplateState();
  const { instance } = useMsal();
  const { i18n, t } = useTranslation();
  const { notificationApi, contextHolder } = notification.useNotification();

  const [documents, setDocuments] = useState([]);
  const [itemsByLanguage, setItemsByLanguage] = useState({});
  const [allMandatoryUploaded, setAllMandatoryUploaded] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    if (asset) {
      fetchData();
    }
  }, [asset]);

  const handleError = (message, description) => {
    notificationApi?.error({ message, description, duration: 5 });
  };

  const fetchData = async () => {
    setTableLoading(true);
    try {
      buildTableItems();
      await fetchDocuments();
    } finally {
      setTableLoading(false);
    }
  };

  const buildTableItems = () => {
    const languages = ['pt', 'en', 'es'];
    setItemsByLanguage(buildItemsByLanguage(languages));
  };

  const buildItemsByLanguage = (languages) => {
    return languages.reduce((items, lang) => {
      items[lang] = stage
        ? stage[lang]?.plan?.steps[0]?.items.map((item) => ({
            id: item._id,
            documentType: item.type,
            mandatoryItem: item.mandatory,
          })) || []
        : [];
      return items;
    }, {});
  };

  const fetchDocuments = async () => {
    try {
      const response = await getFiles(instance, type, asset._id, true);
      setDocuments(
        response.map((doc) => ({
          id: doc.name,
          documentName: doc.name.split('/').pop() || '-',
          uploadedBy: doc.metadata?.userid || '-',
          dateTime: doc.lastModified ? moment(doc.lastModified).format('DD/MM/YYYY HH:mm') : '-',
        })),
      );
    } catch (err) {
      handleError('Failed to fetch documents', err?.message);
    }
  };

  const deleteDocument = async (row) => {
    const matchingDocument = documents.find((doc) =>
      doc.documentName.startsWith(`phase_${phaseTitle}_${stageIndex}_index_${row.index}_`),
    );
    if (!matchingDocument) {
      handleError('Document not found', 'No matching document found.');
      return;
    }

    setPendingApiCalls((prev) => [...prev, 'deleteFile']);
    try {
      await deleteFile(instance, type, asset._id, matchingDocument.id);
      await fetchDocuments();
    } catch (err) {
      handleError('Failed to delete document', err.message);
    } finally {
      setPendingApiCalls((prev) => prev.filter((call) => call !== 'deleteFile'));
    }
  };

  const getItemsByLanguage = () => itemsByLanguage[i18n.language] || [];

  const getUploadedItems = (items) =>
    items.filter((item, index) =>
      documents.some((doc) =>
        doc.documentName.startsWith(`phase_${phaseTitle}_${stageIndex}_index_${index}_`),
      ),
    );

  const getMandatoryItems = (items) => items.filter((item) => item.mandatoryItem);

  const calculateProgress = () => {
    const items = getItemsByLanguage();
    const uploadedItems = getUploadedItems(items);
    const mandatoryItems = getMandatoryItems(getItemsByLanguage());

    if (mandatoryItems.length === 0) {
      setAllMandatoryUploaded(true);

      return items.length > 0 ? Math.round((uploadedItems.length / items.length) * 100) : 0;
    } else {
      setAllMandatoryUploaded(
        uploadedItems.filter((up) => mandatoryItems.some((mand) => mand.id === up.id)).length ===
          mandatoryItems.length,
      );

      return mandatoryItems.length > 0
        ? Math.round(
            (uploadedItems.filter((up) => mandatoryItems.some((mand) => mand.id === up.id)).length /
              mandatoryItems.length) *
              100,
          )
        : 0;
    }
  };

  useEffect(() => {
    setStepProgress((current) => ({ ...current, [phaseTitle]: calculateProgress() }));
    if (allMandatoryUploaded) {
      setAllTasks((prev) => [...prev, { test: { _id: testId }, report: { status: 'approved' } }]);
    } else {
      setAllTasks((prev) => [
        ...prev?.filter((t) => t.test._id != testId),
        { test: { _id: testId }, report: { status: 'pending' } },
      ]);
    }
  }, [documents, itemsByLanguage, allMandatoryUploaded]);

  const mergeDataForTable = () => {
    const items = getItemsByLanguage();
    return items.map((item, index) => {
      const matchingDocument = documents.find((doc) =>
        doc.documentName.startsWith(`phase_${phaseTitle}_${stageIndex}_index_${index}_`),
      );

      return {
        ...item,
        index,
        documentName: matchingDocument?.documentName.split('_').slice(5).join('_') || '-',
        uploadedBy: matchingDocument?.uploadedBy || '-',
        dateTime: matchingDocument?.dateTime || '-',
        manage: 'placeholder',
      };
    });
  };

  const tableSettings = getTableConfig({
    t,
    isLocked,
    deleteDocument,
    fetchDocuments: async () => {
      setTableLoading(true);
      await fetchDocuments();
      setTableLoading(false);
    },
    asset,
    type,
    saveFile,
    phaseTitle,
    stageIndex,
  });

  return (
    <div style={{ height: '100%', width: '100%' }}>
      {contextHolder}
      <Table
        items={mergeDataForTable()}
        handleSelect={null}
        handleRowClick={null}
        startingTotalItems={mergeDataForTable().length ?? 0}
        tableSettings={{
          ...tableSettings,
        }}
        apiCall={async () => ({
          items: mergeDataForTable(),
          totalItems: mergeDataForTable().length,
        })}
        loadingSkeleton={tableLoading}
      />
    </div>
  );
};

export default DocumentUpload;
