import { InfoCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const FAQButton = () => {
  const navigate = useNavigate();

  return (
    <Button
      icon={<InfoCircleOutlined />}
      size='large'
      style={{ border: 'none' }}
      className='infoButton'
      onClick={() => {
        navigate('/virtual-engineer/faq');
      }}
    >
      FAQ
    </Button>
  );
};

export default FAQButton;
