import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

import Forms from '../../../../../../Template/layoutComponents/Forms';
import { useEffect, useState } from 'react';

import useTemplateState from '../../../../../../Template/atoms/variables';
import './style.css';
import { useNavigate, useParams } from 'react-router-dom';
import Tooltip from '../../../../../../Template/layoutComponents/Tooltip';

import Checkbox from '../../../../../../Template/layoutComponents/Checkmark';
import Select from '../../../../../../Template/layoutComponents/SingleSelect';
import Input from '../../../../../../Template/layoutComponents/Input';
import SecondaryCategories from '../../../../../components/SecondaryCategories';

import { PlusOutlined, CloseCircleOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import { Button, notification } from 'antd';
import moment from 'moment';
import {
  updateCategory,
  updateInstrumentCategory,
  updateInterconnectionCategory,
} from '../../../../../helpers/apiCalls';
import useSettingsState from '../../../../../atoms/variables';

const GeneralTab = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [notificationApi, contextHolder] = notification.useNotification();

  const { companyInfo, setPendingApiCalls } = useTemplateState();
  const { viewDetailsCategory, setViewDetailsCategory } = useSettingsState();
  const { chosenType } = useParams();

  const [chosenCategory, setChosenCategory] = useState(viewDetailsCategory);
  const [editingCategory, setEditingCategory] = useState(false);

  const [certificationInterval, setCertificationInterval] = useState(365);
  const [certificationScale, setCertificationScale] = useState(1);

  const [halfMain, setHalfMain] = useState(0);

  useEffect(() => {
    setChosenCategory(viewDetailsCategory);
  }, [viewDetailsCategory]);

  useEffect(() => {
    setHalfMain(Math.ceil(chosenCategory?.properties.filter((prop) => prop.main).length / 2));
  }, [chosenCategory]);

  const disableCreateSave = () => {
    if (editingCategory)
      return chosenCategory?.properties.some(
        (prop) =>
          (prop.main && prop.value === null) ||
          (prop.main && prop.value === '') ||
          (prop.main && typeof prop.value === 'string' && prop.value.replace(/ /g, '') === '') ||
          prop.value === t('new_category') ||
          !prop.type ||
          prop.label == '' ||
          prop.label.replace(/ /g, '') === '' ||
          prop.label == t('new_property') ||
          prop.options?.some((opt) => opt?.label == '') ||
          (prop.type.toLowerCase().includes('select') && prop.options?.length === 0),
      );

    return true;
  };

  return (
    <div className='generalTab' style={{ paddingTop: 0 }}>
      {contextHolder}
      <div className='propsForm mainProps'>
        <div className='columnTitle' style={{ paddingBottom: '2rem' }}>
          {t('main_properties')}
        </div>
        <div className='propsFormContent'>
          <div className='propsColumn firstColumn'>
            <Forms
              id={`${chosenCategory?.id}-main-props`}
              inputs={chosenCategory?.properties.filter((prop) => prop.main).slice(0, halfMain)}
              onInputChange={(event, inputIndex) => {}}
            />
          </div>
          {chosenCategory?.properties.filter((prop) => prop.main).slice(halfMain).length > 0 && (
            <div className='propsColumn secondColumn'>
              <Forms
                id={`${chosenCategory?.id}-cat-props`}
                inputs={chosenCategory?.properties.filter((prop) => prop.main).slice(halfMain)}
                onInputChange={(event, inputIndex) => {}}
              />
            </div>
          )}
        </div>
      </div>
      {(chosenType == 'assets' || chosenType === 'interconnections') &&
        companyInfo?.commissioning && (
          <div
            className='categoryCommissioning detailPage'
            style={
              chosenType == 'assets' || chosenType === 'interconnections'
                ? {}
                : { borderTop: 'none' }
            }
          >
            {(chosenType == 'assets' || chosenType === 'interconnections') && (
              <div className='categoryCommissioningTitleContainer'>
                <div className='categoryCommissioningTitle'>{t('commissioning')}</div>
                <Tooltip />
              </div>
            )}
            {(chosenType == 'assets' || chosenType === 'interconnections') && (
              <div className='categoryCommissioningCheck'>
                <Checkbox
                  label={t('commissionable_category')}
                  checked={chosenCategory?.commissionable}
                  onChange={(e) => {
                    setChosenCategory((current) => {
                      setEditingCategory(true);

                      return { ...current, commissionable: e.target.value };
                    });
                  }}
                />
              </div>
            )}
          </div>
        )}
      {chosenType === 'instruments' && (
        <div className='categoryCommissioning detailPage'>
          <div className='categoryCommissioningTitleContainer'>
            <div className='categoryCommissioningTitle'> {t('certifiable_category')}</div>
            <Tooltip />
          </div>

          <div className='formsBody'>
            <div className='formsInputContainer'>
              <div className='formsInputLabel'>{t('certifiable_text')}</div>
              <div className='formsInput'>
                <Checkbox
                  key={`certificable-0-${chosenCategory?.id}-${chosenCategory?.certifiable}`}
                  label=''
                  checked={chosenCategory?.certifiable}
                  onChange={(e) => {
                    setChosenCategory((current) => {
                      setEditingCategory(true);

                      if (!e.target.value) {
                        setCertificationInterval(365);
                        setCertificationScale(1);
                      }
                      return {
                        ...current,
                        certifiable: e.target.value,
                        certification_cycle: 365,
                      };
                    });
                  }}
                />
              </div>
            </div>
            <div className='formsInputContainer'>
              <div className='formsInputLabel'>{t('certifiable_interval')}:</div>
              <div className='formsInput'>
                <Input
                  key={`certificable-1-${chosenCategory?.id}-${chosenCategory?.certifiable}`}
                  disabled={!chosenCategory?.certifiable}
                  value={
                    chosenCategory?.certification_cycle
                      ? `${chosenCategory?.certification_cycle}`
                      : `${certificationInterval}`
                  }
                  type={'number'}
                  onChange={(e) => {
                    setChosenCategory((current) => {
                      setEditingCategory(true);

                      setCertificationInterval(e.target.value ? parseInt(e.target.value) : 0);

                      return {
                        ...current,
                        certification_cycle: e.target.value
                          ? parseInt(e.target.value) * certificationScale
                          : 0 * certificationScale,
                      };
                    });
                  }}
                />
              </div>
            </div>
            <div className='formsInputContainer'>
              <div className='formsInputLabel'>{t('certifiable_scale')}:</div>
              <div className='formsInput'>
                <Select
                  key={`certificable-2-${chosenCategory?.id}-${chosenCategory?.certifiable}`}
                  disabled={!chosenCategory?.certifiable}
                  value={{ id: `${certificationScale}` }}
                  options={[
                    { id: '1', label: t('days') },
                    { id: '30', label: t('months') },
                    { id: '365', label: t('years') },
                  ]}
                  onChange={(e) => {
                    setChosenCategory((current) => {
                      setEditingCategory(true);

                      setCertificationScale(parseInt(e.target.value));

                      return {
                        ...current,
                        certification_cycle: certificationInterval * parseInt(e.target.value),
                      };
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <SecondaryCategories
        detailPage
        chosenCategory={chosenCategory}
        setChosenCategory={setChosenCategory}
        setEditingCategory={setEditingCategory}
        companyInfo={companyInfo}
      />
      {chosenCategory && (
        <div className='newPropertyButtonContainer'>
          <div className='newPropertyButton'>
            <Button
              disabled={false}
              icon={<PlusOutlined />}
              size='large'
              style={{
                borderColor: '#1777FF',
                background: 'white',
                color: '#1777FF',
              }}
              onClick={() => {
                setEditingCategory(true);
                setChosenCategory((current) => {
                  return {
                    ...current,
                    properties: [...current.properties].concat([
                      {
                        label: t('new_property'),
                        type: null,
                        value: '',
                        main: false,
                        required: false,
                        disabled: false,
                        key: `newproperty-${moment()}`,
                      },
                    ]),
                  };
                });
              }}
            >
              {t('new_property')}
            </Button>
          </div>
        </div>
      )}
      <div className='manageCategoriesButtonsContainer detailPage'>
        {editingCategory && (
          <Button
            id='cancel'
            size='large'
            icon={<CloseCircleOutlined />}
            style={{
              borderColor: 'black',
              background: 'white',
              color: 'black',
            }}
            onClick={() => {
              navigate(`/settings/asset-tree/manage/${chosenType}`);
            }}
          >
            {t('cancel_changes')}
          </Button>
        )}
        {chosenCategory && (
          <Button
            id='create'
            className={disableCreateSave() ? 'disabled' : ''}
            size='large'
            disabled={disableCreateSave()}
            style={{
              borderColor: '#1777FF',
              background: '#1777FF',
              color: 'white',
            }}
            icon={<AppstoreAddOutlined />}
            onClick={async () => {
              // SEND ITEM TO BFF (UPDATE)
              try {
                setPendingApiCalls((current) => current.concat(['updateCategory']));

                if (chosenType === 'instruments') {
                  await updateInstrumentCategory(instance, chosenCategory);
                } else if (chosenType === 'interconnections') {
                  await updateInterconnectionCategory(instance, chosenCategory);
                } else {
                  await updateCategory(instance, chosenCategory);
                }

                setViewDetailsCategory(chosenCategory);
              } catch (err) {
                // API fail notification
                notificationApi.error({
                  message: t('error_api.update_category_failed'),
                  description: err?.response?.data?.message
                    ? `${err?.response?.data?.message} (${err?.response?.status})`
                    : err?.message,
                  duration: 5,
                });
              } finally {
                setPendingApiCalls((current) =>
                  current.filter((item) => item !== 'updateCategory'),
                );
              }

              setEditingCategory(false);
            }}
          >
            {t('save_item_settings')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default GeneralTab;
