import Markdown from 'react-markdown';

import { useState } from 'react';
import { useEffect } from 'react';

import contentPt from './pageContent-pt.md';
import contentEn from './pageContent-en.md';
import contentEs from './pageContent-es.md';

import './style.css';
import { useTranslation } from 'react-i18next';

const FAQPage = () => {
  const [pageContent, setPageContent] = useState({ markdown: '' });
  const { i18n } = useTranslation();

  useEffect(() => {
    getPageContentFromFile(i18n.language);
  }, [i18n.language]);

  const getPageContentFromFile = async (language) => {
    const content = {
      pt: contentPt,
      en: contentEn,
      es: contentEs,
    };

    const file = await fetch(content[language]);
    const text = await file.text();
    setPageContent({ markdown: text });
  };

  return (
    <div id='faqContent'>
      <div className='content'>
        <Markdown className={'md'} children={pageContent.markdown} />
      </div>
    </div>
  );
};

export default FAQPage;
