import { useEffect, useState } from 'react';
import Table from '../../../../../Template/layoutComponents/Table';
import { getWoAndUniques } from '../../../../helpers/apiCalls';
import { useNavigate, useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import useTemplateState from '../../../../../Template/atoms/variables';
import useMaintenanceState from '../../../../atoms/variables';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import TableTip from '../../../../../Template/layoutComponents/Table/components/tip';
import { parseIsoDateString } from '../../../../../helpers/misc';

const WosDetailPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { instance } = useMsal();

  const { plantId, assetId, interconnectionId } = useParams();

  const { setPendingApiCalls } = useTemplateState();
  const { setViewDetailsItem } = useMaintenanceState();
  const [notificationApi, contextHolder] = notification.useNotification();

  const [wos, setWos] = useState({ items: [], totalItems: 0 });

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getWoAndUniques']));

      const responses = await Promise.all([
        getWoAndUniques(instance, {
          location: plantId,
          limit: 10,
          sort: `-scheduled_date`,
          'subject_list.subject': assetId ?? interconnectionId,
        }),
      ]);
      const woReponse = responses[0];

      setWos(woReponse);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getWoAndUniques'));
    }
  };

  return (
    <div style={{ paddingTop: '2rem', height: '100%' }}>
      <Table
        startingSort={{ column: 'scheduled_date', asc: false }}
        handleSelect={null}
        handleRowClick={(row) => {
          setViewDetailsItem({ ...row, id: row.id });
          navigate(`/maintenance/plants/${plantId}/work-orders/${row._id}`);
        }}
        apiCall={async (instance, queryParams) => {
          const response = await getWoAndUniques(instance, {
            location: plantId,
            'subject_list.subject': assetId ?? interconnectionId,
            ...queryParams,
          });

          return {
            ...response,
            items: response.items.map((item) => ({
              ...item,
              company: item?.employee?.properties?.find((prop) => prop.key === 'company')?.value,
            })),
          };
        }}
        items={wos?.items.map((item) => ({
          ...item,
          company: item?.employee?.properties?.find((prop) => prop.key === 'company')?.value,
        }))}
        startingTotalItems={wos.totalItems ?? 0}
        tableSettings={{
          columnsConfig: {
            token: {
              label: t('token'),
            },
            company: {
              label: t('contractor'),
            },
            employee: {
              label: t('executor'),
              customElement: (item) =>
                item.employee?.properties?.find((prop) => prop.key === 'name')?.value,
            },
            scheduled_date: {
              label: t('date'),
              replaceValue: (value) => {
                return {
                  label: parseIsoDateString(new Date(value).toISOString()),
                  id: value,
                };
              },
            },
            status: {
              label: t('status'),
              customElement: (item) => <TableTip children={item.status ?? item} />,
            },
          },
          sortable: ['scheduled_date', 'status'],
          listFilter: ['status'],
          searchFilter: ['token'],
          uniqueValues: wos.uniqueValues,
        }}
      />
    </div>
  );
};

export default WosDetailPage;
