import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useMaintenanceState from '../../../Maintenance/atoms/variables';
import useTemplateState from '../../../Template/atoms/variables';
import Forms from '../../../Template/layoutComponents/Forms';
import { useEffect, useState } from 'react';
import { Button, notification, Tooltip } from 'antd';

import { getUsers } from '../../../Settings/helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
import InterconnectionTable from './components/interconnectionTable';
import AssetTable from './components/assetTable';

import TaskTable from './components/taskTable';
import { createWo } from '../../helpers/apiCalls';
import TokenPreviewer from './components/tokenPreviewer';
import './style.css';

const NewWoModal = ({ preSelectedSubjectKind, selectedSubjects, setForceReload }) => {
  const { t } = useTranslation();
  const { plantId } = useParams();
  const [notificationApi, contextHolder] = notification.useNotification();
  const { instance } = useMsal();

  const { setModalOpen, setModalContent, setPendingApiCalls, setModalTitle } = useTemplateState();

  const { chosenPlant } = useMaintenanceState();

  const [chosenItem, setChosenItem] = useState({
    properties: [
      {
        label: t('location'),
        type: 'text',
        value: chosenPlant?.name,
        options: [],
        main: true,
        required: true,
        disabled: true,
        key: 'location',
      },
      {
        label: t('subject_kind'),
        type: 'multiSelect',
        value: null,
        options: [
          { id: 'asset', label: t('assets') },
          { id: 'interconnection', label: t('interconnections') },
        ],
        main: true,
        required: true,
        key: 'subject_kind',
      },
      {
        label: t('contractor'),
        type: 'singleSelect',
        value: null,
        options: [],
        main: true,
        required: true,
        key: 'contractor',
      },
      {
        label: t('executor'),
        type: 'singleSelect',
        value: null,
        options: [],
        main: true,
        required: true,
        key: 'executor',
      },
      {
        label: t('language'),
        type: 'singleSelect',
        value: null,
        options: [
          { id: 'en', label: 'English' },
          { id: 'pt', label: 'Português' },
          { id: 'es', label: 'Español' },
        ],
        main: true,
        required: true,
        key: 'language',
      },
      {
        label: t('scheduled_date'),
        type: 'date',
        value: null,
        main: true,
        required: true,
        key: 'scheduled_date',
      },
    ],
  });
  const [currentStep, setCurrentStep] = useState(0);

  const [contractors, setContractors] = useState([]);
  const [executors, setExecutors] = useState([]);

  const [selectedObjects, setSelectedObjects] = useState(selectedSubjects ?? []);
  const [token, setToken] = useState(null);

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getExecutors']));

      const responses = await Promise.all([
        getUsers(instance, { populate: ['user_group'].join(';') }),
      ]);

      const users = responses[0];
      const exec = users?.items
        ?.filter((user) => user?.user_group?.some((g) => g.access_level === 'executor'))
        .map((user) => ({
          ...user,
          label: user?.properties?.find((p) => p.key === 'name')?.value ?? user.email,
          company: user?.properties?.find((p) => p.key === 'company')?.value,
          id: user._id,
        }));

      // Filtrar contractors únicos usando reduce
      const uniqueContractors = exec.reduce((acc, user) => {
        if (!acc.some((item) => item.id === user.company)) {
          acc.push({ label: user.company, id: user.company });
        }
        return acc;
      }, []);

      setContractors(uniqueContractors);
      setExecutors(exec);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getExecutors'));
    }
  };

  useEffect(() => {
    setChosenItem({
      properties: [
        {
          label: t('location'),
          type: 'text',
          value: chosenPlant?.name,
          options: [],
          main: true,
          required: true,
          disabled: true,
          key: 'location',
        },
        {
          label: t('subject_kind'),
          type: 'multiSelect',
          value: preSelectedSubjectKind ?? [],
          options: [
            { id: 'asset', label: t('assets') },
            { id: 'interconnection', label: t('interconnections') },
          ],
          disabled: preSelectedSubjectKind ? true : false,
          main: true,
          required: true,
          key: 'subject_kind',
        },
        {
          label: t('contractor'),
          type: 'singleSelect',
          value: null,
          options: contractors,
          main: true,
          required: true,
          key: 'contractor',
        },
        {
          label: t('executor'),
          type: 'singleSelect',
          value: null,
          options: executors,
          main: true,
          required: true,
          key: 'executor',
        },
        {
          label: t('language'),
          type: 'singleSelect',
          value: null,
          options: [
            { id: 'en', label: 'English' },
            { id: 'pt', label: 'Português' },
            { id: 'es', label: 'Español' },
          ],
          main: true,
          required: true,
          key: 'language',
        },
        {
          label: t('scheduled_date'),
          type: 'date',
          value: null,
          main: true,
          required: true,
          key: 'scheduled_date',
        },
      ],
    });
  }, [t, executors, contractors, preSelectedSubjectKind, selectedSubjects]);

  useEffect(() => {
    setModalTitle(
      [
        {
          open: true,
          title: t('new_wo'),
        },
        {
          open:
            chosenItem.properties
              .find((prop) => prop.key === 'subject_kind')
              ?.value?.some((o) => o.id === 'asset') && !selectedSubjects,
          title: `${t('select')} ${t('assets')}`,
        },
        {
          open:
            chosenItem.properties
              .find((prop) => prop.key === 'subject_kind')
              ?.value?.some((o) => o.id === 'interconnection') && !selectedSubjects,
          title: `${t('select')} ${t('interconnections')}`,
        },
        {
          open: true,
          title: `${t('select')} ${t('tasks')}`,
        },
        {
          open: true,
          title: t('token'),
        },
      ].filter((c) => c.open)[currentStep]?.title ?? '',
    );
  }, [currentStep]);

  const updateCurrentEditingItem = (current, inputIndex, event) => {
    return {
      ...current,
      properties: [
        ...current.properties.map((item, index) => {
          if (index === inputIndex) {
            return {
              ...item,
              value: item.type.toLowerCase().includes('select')
                ? Array.isArray(event.target.value)
                  ? event.target.value.map((value) => ({
                      id: value,
                      label: '',
                    }))
                  : { id: event.target.value, label: '' }
                : item.type.toLowerCase().includes('bool')
                  ? event.target.value
                  : event.target.value,
            };
          }
          return item;
        }),
      ],
    };
  };

  const disableNext = () => {
    if (currentStep === 0) {
      return chosenItem.properties.some(
        (prop) => prop.required && (!prop.value || prop.value.length === 0),
      );
    } else if (currentStep < handleNumberOfSteps() - 1) {
      return (
        selectedObjects.filter((obj) => !obj.committed).length === 0 || selectedObjects.length > 15
      );
    } else {
      return !selectedObjects.some((obj) => obj.pendingTasks.some((t) => t.checked));
    }
  };

  const getNextTooltip = () => {
    if (selectedObjects.length > 15) return t('selected_items_exceeds_limit');
  };

  const extractFromProperties = (searchedKey) => {
    const found = chosenItem.properties.find((prop) => prop.key === searchedKey);

    return found ? (found?.value?.id ?? found.value) : null;
  };

  const handleNumberOfSteps = () => {
    if (selectedSubjects) {
      return 2;
    } else {
      return chosenItem.properties.find((prop) => prop.key === 'subject_kind')?.value?.length + 2;
    }
  };

  return (
    <div className='newWOModalBody'>
      {contextHolder}
      {
        [
          {
            open: true,
            component: (
              <div style={{ minHeight: '10rem' }}>
                <Forms
                  inputs={chosenItem.properties.map((prop) => {
                    if (prop.key === 'executor') {
                      return {
                        ...prop,
                        options: executors.filter(
                          (user) =>
                            user.company ===
                            chosenItem.properties.find((p) => p.key === 'contractor')?.value?.id,
                        ),
                        disabled: !chosenItem.properties.find((p) => p.key === 'contractor')?.value
                          ?.id,
                      };
                    }
                    return prop;
                  })}
                  onInputChange={(event, inputIndex) => {
                    setChosenItem((current) => {
                      if (current) {
                        return updateCurrentEditingItem(current, inputIndex, event);
                      }
                      return current;
                    });
                  }}
                />
              </div>
            ),
          },
          {
            open:
              chosenItem.properties
                .find((prop) => prop.key === 'subject_kind')
                ?.value?.some((o) => o.id === 'asset') && !selectedSubjects,
            component: (
              <div className='equipmentTable'>
                <AssetTable
                  selectedObjects={selectedObjects}
                  setSelectedObjects={setSelectedObjects}
                />
              </div>
            ),
          },
          {
            open:
              chosenItem.properties
                .find((prop) => prop.key === 'subject_kind')
                ?.value?.some((o) => o.id === 'interconnection') && !selectedSubjects,
            component: (
              <div className='equipmentTable'>
                <InterconnectionTable
                  selectedObjects={selectedObjects}
                  setSelectedObjects={setSelectedObjects}
                />
              </div>
            ),
          },
          {
            open: true,
            component: (
              <div className='equipmentTable'>
                <TaskTable
                  language={
                    chosenItem.properties.find((prop) => prop.key === 'language')?.value?.id
                  }
                  selectedObjects={selectedObjects}
                  setSelectedObjects={setSelectedObjects}
                />
              </div>
            ),
          },
          {
            open: true,
            component: <TokenPreviewer token={token} />,
          },
        ].filter((c) => c.open)?.[currentStep]?.component
      }
      <div
        className='uploadSheetModalButtons'
        style={
          currentStep < handleNumberOfSteps()
            ? { justifyContent: 'space-between' }
            : { justifyContent: 'end' }
        }
      >
        {currentStep < handleNumberOfSteps() && (
          <Button
            id='cancel'
            className='backButton'
            size='large'
            style={{
              width: 'fit-content',
              borderColor: 'black',
              background: 'white',
              color: 'black',
            }}
            onClick={() => {
              setModalOpen(false);
              setModalContent(null);
            }}
          >
            {t('cancel-btn')}
          </Button>
        )}
        <Tooltip title={getNextTooltip()}>
          <Button
            className='nextButton'
            size='large'
            style={{
              width: 'fit-content',
              alignSelf: 'end',
              borderColor: '#1777FF',
              background: '#1777FF',
              color: 'white',
              opacity: disableNext() ? 0.5 : 1,
            }}
            disabled={disableNext()}
            onClick={async () => {
              if (currentStep < handleNumberOfSteps() - 1) {
                setCurrentStep(currentStep + 1);
              } else if (currentStep == handleNumberOfSteps() - 1) {
                try {
                  setPendingApiCalls((current) => current.concat(['createWo']));
                  const response = await createWo(instance, {
                    employee: extractFromProperties('executor'),
                    location: plantId,
                    scheduled_date: extractFromProperties('scheduled_date'),
                    subject_list: selectedObjects
                      .map((obj) => {
                        return {
                          // não estamos enviando subject_type. está dando certo pq o default é 'Interconnection', mas vamos precisar corrigir isso
                          subject: obj._id,
                          tests: obj.pendingTasks.filter((t) => t.checked).map((t) => t._id),
                        };
                      })
                      .filter((task) => task.tests?.length > 0),
                    language: extractFromProperties('language'),
                  });

                  setToken(response.token);
                } catch (err) {
                  notificationApi.error({
                    message: t('database_communication_failure'),
                    description: err?.response?.data?.message
                      ? `${err?.response?.data?.message} (${err?.response?.status})`
                      : err?.message,
                    duration: 5,
                  });
                } finally {
                  setPendingApiCalls((current) => current.filter((item) => item !== 'createWo'));
                }

                setCurrentStep(currentStep + 1);
              } else {
                if (setForceReload) setForceReload(true);
                setModalOpen(false);
                setModalContent(null);
              }
              setSelectedObjects((current) => current.map((obj) => ({ ...obj, committed: true })));
            }}
          >
            {currentStep < handleNumberOfSteps() - 1
              ? t('next')
              : currentStep == handleNumberOfSteps() - 1
                ? t('create_child')
                : t('refresh')}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default NewWoModal;
