import { Button, notification } from 'antd';
import { useTranslation } from 'react-i18next';

import Table from '../../../../../Template/layoutComponents/Table';
import TableTip from '../../../../../Template/layoutComponents/Table/components/tip';
import RenderBool from '../../../../../Template/layoutComponents/Table/components/boolValues';

import { useNavigate, useParams } from 'react-router-dom';

import useMaintenanceState from '../../../../atoms/variables';
import { findObjectById } from '../../../../../helpers/misc';
import { useEffect, useState } from 'react';
import useTemplateState from '../../../../../Template/atoms/variables';
import {
  deleteSystem,
  getExistingAssetTrees,
  getSystemChildrenDetails,
} from '../../../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';

import {
  getAllCategories,
  getInterconnectionCategories,
} from '../../../../../Settings/helpers/apiCalls';

const ViewSystem = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { instance } = useMsal();

  const { plantId, systemId } = useParams();
  const {
    chosenSystem,
    setChosenSystem,
    chosenPlant,
    setChosenPlant,
    setExistingAssetTrees,
    setViewDetailsItem,
    systems,
    setSystems,
  } = useMaintenanceState();
  const { setPendingApiCalls } = useTemplateState();
  const [notificationApi, contextHolder] = notification.useNotification();

  const [childrenDetails, setChildrenDetails] = useState(null);

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (chosenSystem) triggerApiCalls();
  }, [chosenSystem]);

  useEffect(() => {
    if (!chosenSystem) {
      setChosenSystem(systems?.find((sys) => sys.id === systemId));
    }
  }, [systems]);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) =>
        current.concat(['getAllCategories', 'getSystemChildrenDetails']),
      );

      const responses = await Promise.all([
        getAllCategories(instance),
        getInterconnectionCategories(instance),
        getSystemChildrenDetails(instance, {
          _ids: chosenSystem?.children.length > 0 ? chosenSystem?.children : [],
          limit: 10,
        }),
      ]);

      setCategories([...responses[0], ...responses[1]]);
      setChildrenDetails(responses[2]);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) =>
        current.filter(
          (item) => item !== 'getSystemChildrenDetails' && item !== 'getAllCategories',
        ),
      );
    }
  };

  return (
    <div className='instrumentsContent'>
      {contextHolder}
      <div className='instrumentsContentHeader'>
        <div className='pageTitle'>{chosenSystem?.name}</div>
        <div className='pageButtons'>
          <Button
            id='cancel'
            size='large'
            style={{
              borderColor: 'black',
              background: 'white',
              color: 'black',
            }}
            onClick={async () => {
              try {
                setPendingApiCalls((current) => current.concat(['deleteSystem']));
                await deleteSystem(instance, systemId);

                const response = await getExistingAssetTrees(instance);
                setExistingAssetTrees(response);
                setChosenPlant(response.find((plant) => plant.id === plantId));

                setSystems((current) => current.filter((s) => s.id !== systemId));
                navigate(`/maintenance/plants/${plantId}/systems`);
              } catch (err) {
                notificationApi.error({
                  message: t('database_communication_failure'),
                  description: err?.response?.data?.message
                    ? `${err?.response?.data?.message} (${err?.response?.status})`
                    : err?.message,
                  duration: 5,
                });
              } finally {
                setPendingApiCalls((current) => current.filter((item) => item !== 'deleteSystem'));
              }
            }}
          >
            {`${t('delete')} ${t('system')}`}
          </Button>
          <Button
            className='newItemButton'
            size='large'
            style={{
              borderColor: '#1777FF',
              background: '#1777FF',
              color: 'white',
            }}
            onClick={async () => {
              navigate(`/maintenance/plants/${plantId}/systems/${systemId}`, {
                state: { items: childrenDetails.items },
              });
            }}
          >
            {t('edit_system')}
          </Button>
        </div>
      </div>
      <Table
        handleSelect={null}
        handleRowClick={(row) => {
          if (row.type.toLowerCase() === 'asset') {
            setViewDetailsItem(findObjectById(chosenPlant, row.id));
            navigate(`/maintenance/plants/${plantId}/assets/${row.id}`);
          } else {
            setViewDetailsItem({ ...row, type: undefined });
            navigate(`/maintenance/plants/${plantId}/interconnections/${row.id}`);
          }
        }}
        apiCall={(instance, params) =>
          getSystemChildrenDetails(instance, {
            _ids: chosenSystem?.children.length > 0 ? chosenSystem?.children : [],
            ...params,
          })
        }
        items={childrenDetails?.items.map((item) => ({
          ...item,
          category: item.category ?? item.category,
        }))}
        startingTotalItems={childrenDetails?.totalItems ?? 0}
        tableSettings={{
          columnsConfig: {
            type: {
              label: t('type'),
            },
            name: {
              label: t('name'),
            },
            category: {
              label: t('category'),
              replaceValue: (value) => {
                return categories?.find((cat) => cat._id === value || cat.id === value);
              },
            },
            status: {
              label: t('status'),
              customElement: (item) => <TableTip children={item.status ?? item} />,
            },
          },
          sortable: [],
          listFilter: ['status'],
          searchFilter: ['name'],
          uniqueValues: childrenDetails?.uniqueValues,
        }}
      />
    </div>
  );
};

export default ViewSystem;
