import React, { useEffect } from 'react';
import Icon, { StarOutlined, StarTwoTone } from '@ant-design/icons';
import { Button } from 'antd';
import useVirtualEngineerState from '../../../../atoms/variables';

import { updateUser } from '../../../../../Settings/helpers/apiCalls';

import { useMsal } from '@azure/msal-react';
import useGlobalState from '../../../../../Template/atoms/variables';
import { notification } from 'antd';
import starOutline from '../../../../images/starOutline.svg';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const Favorite = () => {
  const { currentEquipment, setFavorites } = useVirtualEngineerState();

  const { user, setUser, setPendingApiCalls } = useGlobalState();

  const { t } = useTranslation();
  const { instance } = useMsal();
  const [notificationApi, contextHolder] = notification.useNotification();

  const [isFavorite, setIsFavorite] = useState(
    user?.preferences?.favorites?.some((item) => item.asset_id === currentEquipment?._id) ?? false,
  );

  useEffect(() => {
    setIsFavorite(
      user?.preferences?.favorites?.some((item) => item.asset_id === currentEquipment?._id),
    );
  }, [user, currentEquipment]);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['updateUser']));

      const newFavorites = isFavorite
        ? (user.preferences.favorites || []).filter(
            (item) => item.asset_id !== currentEquipment?._id,
          )
        : (user.preferences.favorites || []).concat([{ asset_id: currentEquipment?._id }]);

      const newUser = {
        ...user,
        preferences: {
          ...user.preferences,
          favorites: newFavorites,
        },
      };

      await updateUser(instance, newUser);

      setFavorites(newFavorites);
      setUser(newUser);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'updateUser'));
    }
  };

  return (
    <Button
      size='large'
      icon={
        isFavorite ? <img src={starOutline} style={{ minWidth: '1.5rem' }} /> : <StarOutlined />
      }
      style={
        isFavorite
          ? {
              minWidth: '5rem',
              background: '#ff8f2d',
              margin: 'black',
            }
          : {
              minWidth: '5rem',
              background: 'white',
              color: 'black',
            }
      }
      value={isFavorite}
      onClick={() => triggerApiCalls()}
    ></Button>
  );
};
export default Favorite;
