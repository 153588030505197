import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';

import useTemplateState from '../../../../Template/atoms/variables';
import { Button, notification } from 'antd';
import { useEffect, useState } from 'react';
import useSettingsState from '../../../atoms/variables';
import { deleteUsers, getUserGroups, getUsers } from '../../../helpers/apiCalls';
import Table from '../../../../Template/layoutComponents/Table';

import useMaintenanceState from '../../../../Maintenance/atoms/variables';

import { getDepartments, getOrgs } from '../../../../Maintenance/helpers/apiCalls';

import './style.css';

const Users = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { instance } = useMsal();

  const { orgs, setOrgs, departments, setDepartments } = useMaintenanceState();
  const { setPendingApiCalls } = useTemplateState();
  const { setViewDetailsCategory, userGroups, setUserGroups } = useSettingsState();
  const [notificationApi, contextHolder] = notification.useNotification();

  const [users, setUsers] = useState();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) =>
        current.concat(['getUsers', 'getDepartments', 'getOrgs', 'getUserGroups']),
      );

      const responses = await Promise.all([
        getUsers(instance, { limit: 10 }),
        getDepartments(instance),
        getOrgs(instance),
        getUserGroups(instance),
      ]);
      setUsers(responses[0]);
      setDepartments(responses[1]);
      setOrgs(responses[2]);
      setUserGroups(responses[3]);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) =>
        current.filter(
          (item) =>
            item !== 'getUsers' &&
            item !== 'getDepartments' &&
            item !== 'getOrgs' &&
            item !== 'getUserGroups',
        ),
      );
    }
  };

  function getUserName(row) {
    const userAccount = instance.getAccountByHomeId(row.user_id)?.idTokenClaims;
    if (userAccount) {
      return userAccount.name ?? `${userAccount.given_name} ${userAccount.family_name}`;
    }
    if (row.name !== '') {
      return row.name;
    }
    return row.email;
  }

  return (
    <div className='instrumentsContent'>
      {contextHolder}
      <div className='instrumentsContentHeader'>
        <div className='pageTitle'>{t('users')}</div>
        <div className='pageButtons'>
          <Button
            id='cancel'
            className='newItemButton'
            size='large'
            disabled={selectedUsers.length === 0}
            style={{
              borderColor: 'black',
              background: 'white',
              color: 'black',
              opacity: selectedUsers.length > 0 ? 1 : 0.5,
            }}
            onClick={async () => {
              try {
                setPendingApiCalls((current) => current.concat(['deleteUsers']));
                await deleteUsers(
                  instance,
                  selectedUsers.map((u) => u._id),
                );
                setSelectedUsers([]);
                setReload(true);
              } catch (err) {
                notificationApi.error({
                  message: t('database_communication_failure'),
                  description: err?.response?.data?.message
                    ? `${err?.response?.data?.message} (${err?.response?.status})`
                    : err?.message,
                  duration: 5,
                });
              } finally {
                setPendingApiCalls((current) => current.filter((item) => item !== 'deleteUsers'));
              }
            }}
          >
            {t('delete')}
          </Button>

          <Button
            className='newItemButton'
            size='large'
            icon={<PlusOutlined />}
            style={{
              borderColor: '#1777FF',
              background: '#1777FF',
              color: 'white',
            }}
            onClick={async () => {
              setViewDetailsCategory({
                id: 'newUser',
                name: t('new_user'),
                label: t('new_user'),
                properties: [
                  {
                    label: t('name'),
                    type: 'text',
                    value: '',
                    main: true,
                    required: true,
                    disabled: false,
                    key: 'name',
                  },
                  {
                    label: t('company'),
                    type: 'text',
                    value: '',
                    main: true,
                    required: true,
                    disabled: false,
                    key: 'company',
                  },
                  {
                    label: t('email'),
                    type: 'text',
                    value: '',
                    main: true,
                    required: true,
                    disabled: false,
                    key: 'email',
                  },
                  {
                    label: t('user_group'),
                    type: 'multiSelect',
                    value: [],
                    options: userGroups,
                    main: true,
                    required: true,
                    disabled: false,
                    key: 'user_group',
                  },
                  {
                    label: t('organization'),
                    type: 'singleSelect',
                    value: '',
                    options: orgs,
                    main: true,
                    required: true,
                    disabled: false,
                    key: 'organization',
                  },
                  {
                    label: t('department'),
                    type: 'singleSelect',
                    value: '',
                    options: departments,
                    main: true,
                    required: true,
                    disabled: false,
                    key: 'department',
                  },
                  {
                    label: t('language'),
                    type: 'singleSelect',
                    value: '',
                    options: [
                      {
                        id: 'pt',
                        label: 'Português',
                      },
                      {
                        id: 'en',
                        label: 'English',
                      },
                      {
                        id: 'es',
                        label: 'Español',
                      },
                    ],
                    main: true,
                    required: true,
                    disabled: false,
                    key: 'language',
                  },
                  {
                    label: t('session_timeout'),
                    type: 'singleSelect',
                    value: '',
                    options: [
                      {
                        id: 5,
                        label: '5 min',
                      },
                      {
                        id: 10,
                        label: '10 min',
                      },
                      {
                        id: 30,
                        label: '30 min',
                      },
                    ],
                    main: true,
                    required: true,
                    disabled: false,
                    key: 'session_timeout',
                  },
                  {
                    label: t('idle_login_timeout'),
                    type: 'singleSelect',
                    value: '',
                    options: [
                      {
                        id: 5,
                        label: '5 min',
                      },
                      {
                        id: 10,
                        label: '10 min',
                      },
                      {
                        id: 30,
                        label: '30 min',
                      },
                    ],
                    main: true,
                    required: true,
                    disabled: false,
                    key: 'idle_login_timeout',
                  },
                  {
                    label: 'Fora de serviço',
                    type: 'bool',
                    value: false,
                    main: true,
                    required: false,
                    disabled: false,
                    key: 'out_of_service',
                  },
                ],
              });
              navigate('/settings/users/newUser');
            }}
          >
            {t('new_user')}
          </Button>
        </div>
      </div>
      <Table
        handleSelect={(selected) => {
          setSelectedUsers(selected);
        }}
        handleRowClick={(row) => {
          setViewDetailsCategory({
            ...row,
            id: row.id,
            name: getUserName(row),
            label: getUserName(row),
            properties: [...(row.properties || [])],
          });
          navigate(`/settings/users/${row.id}`);
        }}
        apiCall={getUsers}
        items={users?.items}
        tableSettings={{
          columnsConfig: {
            user_id: {
              label: t('table.fullName'),
              customElement: (item) => {
                return getUserName(item);
              },
            },
            email: { label: t('email') },
            user_group: {
              label: t('user_group'),
              customElement: (item) => {
                return (
                  userGroups?.find((g) => g.id === item)?.label ??
                  item?.user_group
                    ?.map((group) => userGroups.find((g) => g.id === group || g.id === item)?.label)
                    .join(', ') ??
                  '-'
                );
              },
            },
            organization: {
              label: t('location'),
              customElement: (item) => {
                return orgs.find((o) => o.id === item.organization)?.country ?? '-';
              },
            },
            preferences: {
              label: t('language'),
              customElement: (item) => {
                return item?.preferences?.language?.toUpperCase();
              },
            },
          },
          sortable: ['email', 'user_group'],
          searchFilter: ['email'],
          listFilter: ['user_group'],
          uniqueValues: users?.uniqueValues,
        }}
        startingTotalItems={users?.totalItems ?? 0}
        forceReload={reload}
        setForceReload={setReload}
      />
    </div>
  );
};

export default Users;
