import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

import Forms from '../../../Template/layoutComponents/Forms';
import { useEffect, useState } from 'react';

import useTemplateState from '../../../Template/atoms/variables';
import './style.css';
import { useNavigate } from 'react-router-dom';

import { CloseCircleOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import { Button, notification } from 'antd';
import useSettingsState from '../../../Settings/atoms/variables';

const GeneralTab = ({ detailsLink, creating, updateItem, createItem, noEditing }) => {
  const { instance } = useMsal();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [notificationApi, contextHolder] = notification.useNotification();

  const { setPendingApiCalls } = useTemplateState();
  const { viewDetailsCategory, setViewDetailsCategory } = useSettingsState();

  const [chosenItem, setChosenItem] = useState(viewDetailsCategory);
  const [editingItem, setEditingItem] = useState(false);

  const [creatingItem, setCreatingItem] = useState(creating ?? false);

  useEffect(() => {
    setChosenItem(viewDetailsCategory);
  }, [viewDetailsCategory]);

  const disableCreateSave = () => {
    if (creatingItem) {
      return !chosenItem.properties?.every((prop) => {
        if (prop.required) {
          return prop.value;
        } else {
          return true;
        }
      });
    } else {
      if (editingItem)
        return chosenItem.properties?.some(
          (prop) =>
            (prop.required && prop.value === '') ||
            (prop.required & (typeof prop.value === 'string') &&
              prop.value.replace(/ /g, '') === '') ||
            !prop.type ||
            prop.label == '' ||
            prop.label == t('new_property') ||
            prop.options?.some((opt) => opt?.label == '') ||
            (prop.type.toLowerCase().includes('select') && prop.options?.length === 0),
        );

      return true;
    }
  };

  return (
    <div className='generalTab'>
      {contextHolder}
      <div className='propsForm mainProps'>
        <div className='columnTitle' style={{ paddingBottom: '2rem' }}>
          {t('main_properties')}
        </div>
        <div className='propsFormContent'>
          <div className='propsColumn firstColumn'>
            <Forms
              inputs={chosenItem?.properties}
              onInputChange={(event, inputIndex) => {
                setEditingItem(true);

                setChosenItem((current) => {
                  if (current) {
                    return {
                      ...current,
                      properties: current.properties?.map((item, index) => {
                        if (index == inputIndex) {
                          return {
                            ...item,
                            value: item.type.toLowerCase().includes('select')
                              ? Array.isArray(event.target.value)
                                ? event.target.value.map((value) => ({
                                    id: value,
                                    label: '',
                                  }))
                                : { id: event.target.value, label: '' }
                              : event.target.value,
                          };
                        }
                        return item;
                      }),
                      label: current.properties[0].value,
                    };
                  }
                });
              }}
            />
          </div>
        </div>
      </div>

      <div className='manageCategoriesButtonsContainer detailPage'>
        {editingItem && (
          <Button
            id='cancel'
            size='large'
            icon={<CloseCircleOutlined />}
            style={{
              borderColor: 'black',
              background: 'white',
              color: 'black',
            }}
            onClick={() => {
              navigate(`${detailsLink}`);
            }}
          >
            {t('cancel_changes')}
          </Button>
        )}
        {chosenItem && !noEditing && (
          <Button
            id='create'
            className={disableCreateSave() ? 'disabled' : ''}
            size='large'
            disabled={disableCreateSave()}
            style={{
              borderColor: '#1777FF',
              background: '#1777FF',
              color: 'white',
            }}
            icon={<AppstoreAddOutlined />}
            onClick={async () => {
              if (creatingItem) {
                try {
                  setPendingApiCalls((current) => current.concat(['createItem']));
                  const mongoItem = await createItem(instance, chosenItem);
                  setCreatingItem(false);
                  setChosenItem((current) => {
                    const account = instance.getAccountByHomeId(current.user_id)?.idTokenClaims;
                    return {
                      ...current,
                      id: mongoItem._id ?? mongoItem,
                      label: account
                        ? (account.name ?? `${account.given_name} ${account.family_name}`)
                        : current.email,
                    };
                  });
                  navigate(`/settings/users/${mongoItem._id ?? mongoItem}`);
                } catch (err) {
                  // API fail notification
                  notificationApi.error({
                    message: 'Falha ao criar categoria',
                    description: err?.response?.data?.message
                      ? `${err?.response?.data?.message} (${err?.response?.status})`
                      : err?.message,
                    duration: 5,
                  });
                } finally {
                  setPendingApiCalls((current) => current.filter((item) => item !== 'createItem'));
                }
              } else {
                try {
                  setPendingApiCalls((current) => current.concat(['updateItem']));
                  await updateItem(instance, chosenItem);
                } catch (err) {
                  // API fail notification
                  notificationApi.error({
                    message: 'Falha ao atualizar categoria existente',
                    description: err?.response?.data?.message
                      ? `${err?.response?.data?.message} (${err?.response?.status})`
                      : err?.message,
                    duration: 5,
                  });
                } finally {
                  setPendingApiCalls((current) => current.filter((item) => item !== 'updateItem'));
                }
              }
              setEditingItem(false);
            }}
          >
            {t('save_item_settings')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default GeneralTab;
