
import ReportTemplate from './ReportTemplate';
import JSZip from 'jszip';

import { pdf } from '@react-pdf/renderer';
import moment from 'moment';
class TaskReportService {

    static async build(reports, logoUrl, exportZip = false) {
        try {
            if (exportZip) {
                let pdfs = await Promise.all(reports.map(report => this.generatePDF(report, logoUrl)));
                await this.downloadZipContent(pdfs);
            } else {
                const { pdf, fileName } = await this.generatePDF(reports[0], logoUrl);
                await this.downloadPDF(pdf, fileName);
            }
        } catch (error) {
            console.error('Error generating PDFs:', error);
            throw error;
        }
    }
    static async generatePDF(report, logoUrl) {
        try {
            let site = report.subject.location.name;
            let code = report.code;
            let sequential = report.sequential.toString().padStart(4, "0");

            const fileName = report.report.filename ?? `${code}-${site}-${sequential}.pdf`.replace(/\//g, '-');;
            // Criar elemento React com o novo componente
            const document = <ReportTemplate report={report} logoUrl={logoUrl} />;

            const pdfFile = await pdf(document).toBlob();
            return { fileName, pdf: pdfFile };
        } catch (error) {
            console.error('Error generating PDF:', error);
            throw error;
        }
    }

    static downloadPDF(pdfBlob, filename) {
        const url = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
        URL.revokeObjectURL(url);
    }

    static async downloadZipContent(documents) {
        try {
            const zip = new JSZip();

            // Adicionar cada PDF diretamente na raiz do ZIP
            for (const doc of documents) {

                const fileName = doc.fileName.endsWith('.pdf') ? doc.fileName : `${doc.fileName}.pdf`;
                const arrayBuffer = await doc.pdf.arrayBuffer();

                zip.file(fileName, arrayBuffer, {
                    binary: true,
                    createFolders: false
                });
            }

            const content = await zip.generateAsync({
                type: 'blob',
                compression: 'DEFLATE',
                compressionOptions: { level: 9 }
            });

            const timestamp = moment().format('YYYY-MM-DD_HH-mm-ss');
            const zipName = `reports_${timestamp}.zip`;

            const url = URL.createObjectURL(content);
            const link = document.createElement('a');
            link.href = url;
            link.download = zipName;
            link.click();
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error creating ZIP:', error);
            throw error;
        }
    }

}

export default TaskReportService;
