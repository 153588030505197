import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import Table from '../../../../Template/layoutComponents/Table';
import Checkbox from '../../../../Template/layoutComponents/Checkmark';

const TaskTable = ({ language, selectedObjects, setSelectedObjects }) => {
  const { t } = useTranslation();

  const [tableColumns, setTableColumns] = useState(null);

  useEffect(() => {
    const allTests = selectedObjects
      .map((obj) => obj.currentPhaseTests.stages)
      .flat()
      .filter((value, index, self) => self.findIndex((i) => i._id === value._id) === index);

    const columns = allTests.reduce((acc, entry) => {
      acc[entry[language].key] = {
        label: t(entry[language].key),
      };
      return acc;
    }, {});

    setTableColumns({ name: { label: t('name') }, ...columns });
  }, [selectedObjects]);

  const addCheckboxLogic = (items) => {
    try {
      if (!tableColumns) return null;
      return items.map((item, index) => ({
        ...Object.keys(tableColumns).reduce((acc, testKey) => {
          const isTestApplicable = item.currentPhaseTests.stages.some(
            (test) => test[language].key === testKey,
          );
          const pendingTest = item.pendingTasks.some((t) => t[language].key === testKey);
          acc[testKey] = isTestApplicable ? (
            <Checkbox
              key={`checkbox-${item.name}-${index}`}
              label=''
              disabled={!pendingTest}
              checked={!pendingTest ? true : pendingTest.checked}
              onChange={(e) => {
                updateSelectedObjects(item, testKey, e);
              }}
            />
          ) : (
            <div style={{ textAlign: 'center' }}>-</div>
          );

          return acc;
        }, {}),
        name: item.name,
      }));
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  function updateSelectedObjects(item, testKey, e) {
    setSelectedObjects((current) => {
      return current.map((i) => {
        if (i._id === item._id) {
          return {
            ...i,
            pendingTasks: i.pendingTasks.map((t) => {
              if (t[language].key === testKey) {
                return { ...t, checked: e.target.value };
              } else return t;
            }),
          };
        } else return i;
      });
    });
  }

  return (
    <Table
      handleSelect={null}
      handleRowClick={null}
      apiCall={(instance, query) => {
        return {
          page: query.page,
          limit: query.limit,
          totalItems: selectedObjects.length ?? 0,
          totalPages: Math.ceil(selectedObjects.length / query.limit),
          items: addCheckboxLogic(
            selectedObjects.slice(query.limit * (query.page - 1), query.limit * query.page),
          ),
        };
      }}
      items={addCheckboxLogic(selectedObjects)}
      startingTotalItems={selectedObjects.length ?? 0}
      tableSettings={{
        columnsConfig: { ...tableColumns },
        sortable: [],
        listFilter: [],
        searchFilter: [],
      }}
    />
  );
};
export default TaskTable;
