import axios from 'axios';
import { protectedResourcesScopes } from '../../auth/config';
import { acquireToken } from '../../auth/msal.js';
import moment from 'moment';

const apiUrl = `${process.env.REACT_APP_BFF_API}`;
const scope = protectedResourcesScopes.core;

export async function getHierarchy(instance) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/maintenance/hierarchy`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function saveHierarchy(instance, hierarchyLevels) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/maintenance/hierarchy`, hierarchyLevels, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getInterconnectionCategories(instance) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/settings/interconnectionCategory`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function newInterconnectionCategory(instance, category) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/settings/interconnectionCategory`, category, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateInterconnectionCategory(instance, category) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.put(`${apiUrl}/settings/interconnectionCategory/${category.id}`, category, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getAllCategories(instance) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/maintenance/category`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function newCategory(instance, category) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/maintenance/category`, category, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateCategory(instance, category) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.put(`${apiUrl}/maintenance/category/${category.id}`, category, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getInstrumentCategories(instance) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/settings/instrumentCategory`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function newInstumentCategory(instance, category) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/settings/instrumentCategory`, category, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateInstrumentCategory(instance, category) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.put(`${apiUrl}/settings/instrumentCategory/${category.id}`, category, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getCommissioningStages(instance, categoryId) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/commissioning/stages/${categoryId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function newOrganization(instance, organization) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/settings/organization`, organization, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateOrganization(instance, organization) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.put(`${apiUrl}/settings/organization/${organization.id}`, organization, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getUsers(instance, queryParams = {}) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/settings/user`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: queryParams,
    });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function getExecutors(instance, queryParams = {}) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/settings/user/executors`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: queryParams,
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function newUser(instance, user) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/settings/user`, user, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function deleteUsers(instance, ids) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/settings/user/deleteMany`, { _id: ids }, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getCompanies(instance) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/system/company/`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateUser(instance, user) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.put(`${apiUrl}/settings/user/${user.id ?? user._id}`, user, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getUserGroups(instance, queryParams = {}) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/settings/user/group`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: queryParams,
    });
    return data;
  } catch (e) {
    throw e;
  }
}


export async function newUserGroup(instance, userGroup) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/settings/user/group`, userGroup, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getAnalyticsData(instance, companyId, dates, plantId, machineId, sessionThemes) {
  try {
    const accessToken = await acquireToken(instance, scope);

    let [start, end] = [null, null];
    if (dates) {
      [start, end] = dates;
    }

    const date_range_start = start ? moment(start.format('YYYY-MM-DD')).utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ') : null;
    const date_range_end = end ? moment(end.format('YYYY-MM-DD')).utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ') : null;

    const { data } = await axios.get(`${apiUrl}/analytics/virtual-engineer`, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        company_id: companyId,
        date_range_start,
        date_range_end,
        plant_id: plantId,
        machine_id: machineId,
        session_themes: sessionThemes
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateUserGroup(instance, userGroup) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.put(`${apiUrl}/settings/user/group/${userGroup.id}`, userGroup, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export const getKnowledge = async (instance, queryParams = {}) => {
  try {
    const accessToken = await acquireToken(instance, scope);

    const { data } = await axios.get(
      `${apiUrl}/virtual-engineer/documentGenerator/knowledge`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        params: queryParams
      },
    );

    return data;
  } catch (error) {
    console.error('Error confirming knowledge addition:', error);
    throw error;
  }
};


export const updateKnowledge = async (instance, knowledge) => {
  try {
    const accessToken = await acquireToken(instance, scope);

    const { data } = await axios.put(
      `${apiUrl}/virtual-engineer/documentGenerator/knowledge`, knowledge,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );

    return data;
  } catch (error) {
    console.error('Error confirming knowledge addition:', error);
    throw error;
  }
};
