import eye from '../../../../../../../../images/eye.svg';
import eyeActive from '../../../../../../../../images/eye-blue.svg';
import download from '../../../../../../../../images/download.svg';
import downloadActive from '../../../../../../../../images/download-blue.svg';
import useTemplateState from '../../../../../../../../../Template/atoms/variables';
import TaskReportService from '../../../../../../../../../services/TaskReportService';
import ReportTranslationService from '../../../../../../../../../services/ReportTranslationService';
import { useTranslation } from 'react-i18next';
import './style.css';

const ActionCell = ({ item, reportOpen, setReportOpen, setSelectedReport }) => {
  const { companyInfo } = useTemplateState();
  const { i18n } = useTranslation();
  const handleDownloadPDF = async () => {
    if (['Pending', 'Expired'].includes(item.status)) return;
    try {
      const logoUrl = companyInfo?.logo;
      let translatedReport = ReportTranslationService.translateReport(item, i18n.language);
      await TaskReportService.build([translatedReport], logoUrl);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <div className='buttonsContainer'>
      <div
        className={['Pending', 'Expired'].includes(item.status) ? 'button-disabled' : 'button'}
        onClick={(e) => {
          e.stopPropagation();
          if (['Pending', 'Expired'].includes(item.status)) return;
          setReportOpen(!reportOpen);
          setSelectedReport(item);
        }}
      >
        <img src={['Pending', 'Expired'].includes(item.status) ? eye : eyeActive} />
      </div>
      <div
        className={['Pending', 'Expired'].includes(item.status) ? 'button-disabled' : 'button'}
        onClick={(e) => {
          e.stopPropagation();
          handleDownloadPDF();
        }}
      >
        <img src={['Pending', 'Expired'].includes(item.status) ? download : downloadActive} />
      </div>
    </div>
  );
};

export default ActionCell;
