// src/pages/AuthCallback.js

import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

const AuthCallback = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    const handleRedirect = async () => {
      try {
        const response = await instance.handleRedirectPromise();
        if (response) {
          // Extrai o estado passado na solicitação de autenticação
          const state = response.state ? JSON.parse(decodeURIComponent(response.state)) : {};
          const redirectUrl = state.redirectUrl || '/virtual-engineer';
          // Redireciona para a URL desejada
          navigate(redirectUrl, { replace: true });
        } else navigate('/', { replace: true });
      } catch (error) {
        console.error('Erro durante o redirecionamento de autenticação:', error);
        // Opcional: redirecionar para uma página de erro ou login
        navigate('/login', { replace: true });
      }
    };

    handleRedirect();
  }, [instance, navigate]);

  return <div>Autenticando...</div>;
};

export default AuthCallback;
