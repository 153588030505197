import { useParams } from 'react-router-dom';

import Table from '../Table';
import { parseIsoDateString, parseIsoTimeString } from '../../../helpers/misc';
import { getHistoryItems } from '../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import useTemplateState from '../../atoms/variables';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

import './style.css';

const HistoryTab = ({ id }) => {
  const { instance } = useMsal();
  const { t } = useTranslation();
  const [notificationApi, contextHolder] = notification.useNotification();

  const { setPendingApiCalls } = useTemplateState();

  const [historyItems, setHistoryItems] = useState({});

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getHistoryItems']));

      const response = await getHistoryItems(instance, { documentId: id });
      const historyItems = response;
      setHistoryItems(historyItems);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getHistoryItems'));
    }
  };

  return (
    <div id='historyTabContent'>
      {contextHolder}
      <Table
        apiCall={(instance, params) => getHistoryItems(instance, { documentId: id, ...params })}
        items={historyItems?.items}
        handleSelect={false}
        startingTotalItems={historyItems.totalItems ?? 0}
        tableSettings={{
          columnsConfig: {
            user: {
              label: t('actor'),
            },
            timestamp: {
              label: t('date'),
              replaceValue: (value) => ({
                label: `${parseIsoDateString(new Date(value).toISOString())} ${parseIsoTimeString(new Date(value).toISOString())}`,
                id: value,
              }),
            },
            operation: {
              label: t('event'),
            },
          },
          sortable: [],
          listFilter: [],
          searchFilter: [],
          uniqueValues: historyItems.uniqueValues,
          listFilter: ['user'],
          sortable: ['timestamp', 'operation'],
          uniqueValues: historyItems.uniqueValues,
        }}
      />
    </div>
  );
};

export default HistoryTab;
