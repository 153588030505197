import { Button, notification } from 'antd';
import Table from '../../../Template/layoutComponents/Table';

import { PlusOutlined } from '@ant-design/icons';

import './style.css';
import { getAssets, getAssetCategories } from '../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
import useTemplateState from '../../../Template/atoms/variables';
import useMaintenanceState from '../../atoms/variables';
import { useEffect, useState } from 'react';

import { ignore } from 'antd/es/theme/useToken';
import TableTip from '../../../Template/layoutComponents/Table/components/tip';
import { parseIsoDateString } from '../../../helpers/misc';
import RenderBool from '../../../Template/layoutComponents/Table/components/boolValues';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AllAssets = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { instance } = useMsal();

  const { setPendingApiCalls } = useTemplateState();
  const { assets, setAssets, orgs, chosenPlant, setViewDetailsItem } = useMaintenanceState();
  const [notificationApi, contextHolder] = notification.useNotification();

  const [showWoButton, setShowWoButton] = useState(false);
  const [allCategories, setAllCategories] = useState([]);

  const [selectedAssets, setSelectedAssets] = useState([]);

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getAssets', 'getAssetCategories']));

      const responses = await Promise.all([
        getAssets(instance, { limit: 10 }),
        getAssetCategories(instance),
      ]);

      setAssets(responses[0]);
      setAllCategories(responses[1]?.items ?? []);
    } catch (err) {
      notificationApi.error({
        message: 'Falha de comunicação com o banco de dados',
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) =>
        current.filter((item) => item !== 'getAssets' && item !== 'getAssetCategories'),
      );
    }
  };

  return (
    <div className='instrumentsContent'>
      {contextHolder}
      <div className='instrumentsContentHeader'>
        <div className='pageTitle'>{t('all_assets')}</div>
        <div className='pageButtons'></div>
      </div>
      <Table
        handleSelect={null}
        handleRowClick={(row) => {
          setViewDetailsItem({
            ...row,
            id: row.id,
            name: row.name,
            category: row.category,
            properties: row.properties.map((prop) => ({
              ...prop,
              disabled: prop.allowEdit ? false : prop.main,
            })),
          });
          navigate(`/maintenance/plants/${row.location}/assets/${row.id}`);
        }}
        apiCall={getAssets}
        items={assets?.items}
        startingTotalItems={assets.totalItems ?? 0}
        tableSettings={{
          columnsConfig: {
            category: {
              label: t('category'),
              replaceValue: (value) =>
                allCategories?.find((cat) => cat._id === value || cat.id === value),
            },
            name: {
              label: t('name'),
            },
            organization: {
              label: t('organization'),
              replaceValue: (value) => orgs.find((org) => org._id === value || org.id === value),
            },
            serial_number: {
              label: t('serial_number'),
            },
            out_of_service: {
              label: t('out_of_service'),
              customElement: (item) => <RenderBool children={item ? item.out_of_service : false} />,
            },
            status: {
              label: t('status'),
              customElement: (item) => <TableTip children={item.status ?? item} />,
            },
          },
          sortable: [
            'category',
            'name',
            'organization',
            'serial_number',
            'certification_date',
            'out_of_service',
            'status',
          ],
          listFilter: [
            'category',
            'status',
            'organization',
            'out_of_service',
            'certification_date',
          ],
          searchFilter: ['name', 'serial_number'],
          uniqueValues: assets.uniqueValues,
        }}
      />
    </div>
  );
};

export default AllAssets;
