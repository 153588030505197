import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Input, notification, Space } from 'antd';
import useVirtualEngineerState from '../../atoms/variables';

import { getConversation, sendMessage } from '../../helpers/chat';

import assistantPicture from '../../images/assistantPicture.svg';

import { useMsal } from '@azure/msal-react';

import { socket } from '../../services/socket';
import Markdown from 'react-markdown';
import { removeDuplicates, scrollDown } from '../../helpers/misc';
import { getEquipment } from '../../helpers/equipment';
import useGlobalState from '../../../Template/atoms/variables';

import UserIcon from '../../../Template/layoutComponents/UserIcon';
import NewChatModal from '../../components/MessageInfoModal';

import './style.css';
import { startConversation } from '../../helpers/conversationHelper';
import { useTranslation } from 'react-i18next';

export const Chat = () => {
  const { instance } = useMsal();
  const { equipmentId, threadId } = useParams();

  const {
    equipments,
    currentEquipment,
    currentEquipmentId,
    currentThreadId,
    equipmentCategories,
    conversations,
    setCurrentEquipmentId,
    setCurrentEquipment,
    setCurrentThreadId,
    setConversations,
    setCurrentCategory,
  } = useVirtualEngineerState();

  const { setPendingApiCalls } = useGlobalState();

  const [canType, setCanType] = useState(true);
  const { t } = useTranslation();
  const [messageText, setMessageText] = useState('');
  const [messages, setMessages] = useState([]);

  const [notificationApi, contextHolder] = notification.useNotification();

  const navigate = useNavigate();

  useEffect(() => {
    if (threadId) {
      setCurrentThreadId(threadId);
    }
  }, [threadId]);

  useEffect(() => {
    if (equipmentId && equipmentCategories) {
      setCurrentEquipmentId(equipmentId);
      getEquipment(instance, equipmentId).then((equipment) => {
        setCurrentEquipment(equipment);
        setCurrentCategory(
          equipmentCategories.find((category) => category.id === equipment.category),
        );
      });
    }
  }, [equipments, equipmentCategories]);

  useEffect(() => {
    if (currentThreadId) startSocket();

    return () => {
      socket.off('textDelta');
      socket.off('messageDone');
    };
  }, [currentThreadId]);

  const startSocket = () => {
    socket.on('textDelta', (e) => {
      if (currentThreadId === e.conversationId) {
        updateCurrentConversationMessages(e.snapshot);
        scrollDown();
      }
    });

    socket.on('messageDone', (e) => {
      setCanType(true);

      if (currentThreadId === e.conversationId)
        updateCurrentConversationMessages(e.snapshot, e.source);
      else {
        setConversations((current) =>
          current.map((item) => {
            if (item._id == e.conversationId) {
              return {
                ...item,
                messages: [
                  ...messages.filter((e) => e.type !== 'thinking'),
                  { role: 'bot', value: e.snapshot, source: e.source },
                ],
              };
            } else return item;
          }),
        );
      }
      scrollDown();
    });
  };

  const updateCurrentConversationMessages = (newValue, source) => {
    setMessages((current) => {
      const filteredMessages = current.filter(
        (item, index) => item.type != 'thinking' && index != current.length - 1,
      );

      return filteredMessages.concat({
        role: 'bot',
        value: newValue,
        source,
      });
    });
  };

  useEffect(() => {
    if (currentThreadId && equipmentCategories) {
      const selectedConversation = conversations.find(
        (conversation) => conversation._id == currentThreadId,
      );
      if (selectedConversation) {
        setMessages(selectedConversation.messages);
        scrollDown();
      } else {
        // Thread was given, but was not found among loaded conversations. Will try finding thread in DB.
        if (currentThreadId === 'new') {
          startConversation(
            equipmentId,
            instance,
            notificationApi,
            navigate,
            setPendingApiCalls,
            setConversations,
            setCurrentCategory,
            setCurrentEquipment,
            setCurrentThreadId,
          );
        } else loadConversationFromThread();
      }
    }
  }, [currentThreadId, currentEquipment, equipmentCategories, conversations]);

  const loadConversationFromThread = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getConversation', 'getEquipment']));
      const account = instance.getActiveAccount();
      const responses = await Promise.allSettled([
        getConversation(instance, currentThreadId, account.idTokenClaims?.sub),
        getEquipment(instance, equipmentId),
      ]);

      const conversation = responses[0].value ?? null;
      const equipment = responses[1].value ?? null;

      if (equipment) {
        setCurrentEquipment(equipment);
        setCurrentCategory(
          equipmentCategories.find((category) => category.id === equipment.category),
        );
      } else {
        notificationApi.info({
          message: t('empty_database'),
          description: t('missing_items_description'),
          duration: 5,
        });
      }

      if (conversation) {
        setMessages(conversation.messages);

        setConversations(
          (current) =>
            removeDuplicates(
              current.concat([
                {
                  ...conversation,
                  equipment: equipment,
                },
              ]),
              '_id',
            ) ?? [],
        );
        scrollDown();
      } else {
        notificationApi.info({
          message: t('empty_database'),
          description: t('conversation_not_found', { threadId: currentThreadId }),
          duration: 5,
        });
        navigate('/virtual-engineer');
      }
    } catch (err) {
      console.log(err);
    } finally {
      setPendingApiCalls((current) =>
        current.filter((item) => item != 'getConversation' && item != 'getEquipment'),
      );
    }
  };

  useEffect(() => {
    if (messages.length > 0) {
      // update currentConversation with latest messages (client-side)
      setConversations((current) => {
        return current.map((item) => {
          if (item._id == currentThreadId) {
            return {
              ...item,
              messages: messages,
            };
          } else return item;
        });
      });
    }
  }, [messages]);

  const handleSubmit = async () => {
    if (messageText && messageText.length > 0) {
      setCanType(false);
      setMessages((currentMessages) =>
        currentMessages.concat([
          { role: 'user', value: messageText },
          {
            role: 'bot',
            type: 'thinking',
            value: '',
          },
        ]),
      );

      try {
        sendMessage(instance, currentThreadId, currentEquipmentId, socket.id, messageText);
      } catch (err) {
        notificationApi.error({
          message: 'Falha ao enviar mensagem',
          description: err?.response?.data?.message
            ? `${err?.response?.data?.message} (${err?.response?.status})`
            : err?.message,
          duration: 5,
        });
      }
      setMessageText('');

      scrollDown();
    }
  };

  return (
    <div id='chatContent'>
      {contextHolder}
      <div id='chatMessages'>
        {messages.map((message, index) => {
          return messageRow(index, message);
        })}
      </div>
      <div id='chatMessageBar'>
        <Space.Compact
          style={{
            width: '100%',
          }}
        >
          <Input
            disabled={!canType}
            value={messageText}
            style={{ height: '2.5rem' }}
            placeholder='Escreva sua mensagem...'
            onChange={(e) => setMessageText(e.target.value)}
            onPressEnter={handleSubmit}
          />
          <Button
            disabled={!messageText}
            style={{ height: '2.5rem', fontWeight: 'bold' }}
            onClick={handleSubmit}
          >
            Enviar
          </Button>
        </Space.Compact>
      </div>
    </div>
  );
};

const messageRow = (index, message) => {
  return (
    <div
      key={`${message.role}MessageRow-${index}`}
      className={`messageRow ${message.role}MessageRow`}
    >
      {message.role == 'bot' && (
        <div className='messagePicture botMessagePicture'>
          <img src={assistantPicture} />
        </div>
      )}
      <div
        key={`${message.role}MessageBubble-${index}`}
        className={`messageBubble ${message.role}MessageBubble`}
      >
        {message?.type == 'thinking' ? (
          <div className='thinkingContainer'>
            <div className='thinkingDot zero'></div>
            <div className='thinkingDot one'></div>
            <div className='thinkingDot two'></div>
          </div>
        ) : (
          <div className='markdown-message'>
            <Markdown>{message.value}</Markdown>
          </div>
        )}
        {message.source && <NewChatModal source={message.source} />}
      </div>
      {message.role == 'user' && (
        <div className='messagePicture userMessagePicture'>
          <UserIcon />
        </div>
      )}
    </div>
  );
};
