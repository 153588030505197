import { useTranslation } from 'react-i18next';
import Forms from '../../../../../Template/layoutComponents/Forms';
import { useEffect, useState } from 'react';
import { getInterconnectionCategories } from '../../../../../Settings/helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
import useTemplateState from '../../../../../Template/atoms/variables';
import { Button, notification } from 'antd';
import useMaintenanceState from '../../../../atoms/variables';

import Table from '../../../../../Template/layoutComponents/Table';

import { InboxOutlined } from '@ant-design/icons';

import Dragger from 'antd/es/upload/Dragger';
import { uploadInterconnectionXlsx } from '../../../../helpers/apiCalls';
import { useParams } from 'react-router-dom';

import openLine from '../../../../images/openLine.svg';
import closeLine from '../../../../images/closeLine.svg';

import './style.css';

const UploadSheetModal = ({ setForceReload }) => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const [notificationApi, contextHolder] = notification.useNotification();

  const { plantId } = useParams();

  const { departments, orgs, systems, manufacturers, costcenters } = useMaintenanceState();
  const { setPendingApiCalls, setModalOpen, setModalStyle, setModalTitle } = useTemplateState();

  const [chosenItem, setChosenItem] = useState({
    properties: [
      {
        label: t('category'),
        type: 'singleSelect',
        value: null,
        options: [],
        main: true,
        required: true,
        key: 'category',
      },
      {
        label: t('cost_center'),
        type: 'singleSelect',
        value: null,
        options: [],
        main: true,
        required: true,
        key: 'cost_center',
      },
    ],
  });
  const [allCategories, setAllCategories] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [openedDrawers, setOpenedDrawers] = useState([]);

  const [successItems, setSuccessItems] = useState([]);
  const [failItems, setFailItems] = useState([]);
  const [drawers, setDrawers] = useState(<div className='drawers'></div>);

  useEffect(() => {
    triggerApiCalls();
  }, []);

  useEffect(() => {
    setDrawers(
      <div className='drawers'>
        <div className='drawer'>
          <div
            className='head'
            onClick={() => {
              openCloseDrawer('drawer-0-body');
            }}
            style={{ color: '#1677FF' }}
          >
            <img src={openedDrawers.includes('drawer-0-body') ? closeLine : openLine} />
            {t('success')} ({successItems.length})
          </div>
          <div id='drawer-0-body' className='body'>
            <Table
              handleSelect={null}
              handleRowClick={null}
              apiCall={(instance, query) => fakeTableApiCall(instance, query, successItems)}
              items={successItems}
              startingTotalItems={successItems.length ?? 0}
              tableSettings={{
                columnsConfig: {
                  name: {
                    label: t('name'),
                  },
                  file: {
                    label: t('file'),
                  },
                },
                sortable: [],
                listFilter: [],
                searchFilter: [],
              }}
            />
          </div>
        </div>
        <div className='drawer'>
          <div
            className='head'
            onClick={() => {
              openCloseDrawer('drawer-1-body');
            }}
            style={{ color: '#FF4D4F' }}
          >
            <img src={openedDrawers.includes('drawer-1-body') ? closeLine : openLine} />
            {t('fail')} ({failItems.length})
          </div>
          <div id='drawer-1-body' className='body'>
            <Table
              handleSelect={null}
              handleRowClick={null}
              apiCall={(instance, query) => fakeTableApiCall(instance, query, failItems)}
              items={failItems}
              startingTotalItems={failItems.length ?? 0}
              tableSettings={{
                columnsConfig: {
                  name: {
                    label: t('name'),
                  },
                  file: {
                    label: t('file'),
                  },
                  cause: {
                    label: t('cause'),
                  },
                },
                sortable: [],
                listFilter: [],
                searchFilter: [],
              }}
            />
          </div>
        </div>
      </div>,
    );
  }, [successItems, failItems, openedDrawers]);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getInterconnectionCategories']));

      const responses = await Promise.all([getInterconnectionCategories(instance)]);

      setAllCategories(responses[0]);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) =>
        current.filter((item) => item !== 'getInterconnectionCategories'),
      );
    }
  };

  const fakeTableApiCall = (instance, query, items) => {
    return {
      page: query.page,
      limit: query.limit,
      totalItems: items.length,
      totalPages: Math.ceil(items.length / query.limit),
      items: items.slice(query.limit * (query.page - 1), query.limit * query.page),
    };
  };

  const updateCurrentEditingItem = (current, inputIndex, event) => {
    return {
      ...current,
      properties: [
        ...current.properties.map((item, index) => {
          if (index === inputIndex) {
            return {
              ...item,
              value: item.type.toLowerCase().includes('select')
                ? Array.isArray(event.target.value)
                  ? event.target.value.map((value) => ({
                      id: value,
                      label: '',
                    }))
                  : { id: event.target.value, label: '' }
                : item.type.toLowerCase().includes('bool')
                  ? event.target.value
                  : event.target.value,
              // event.target.value?.replace(/ /g, ''),
            };
          }
          return item;
        }),
      ],
    };
  };

  const extractFromProperties = (searchedKey) => {
    const found = chosenItem.properties.find((prop) => prop.key === searchedKey);

    return found ? (found?.value?.id ?? found.value) : null;
  };

  const handleUploadFiles = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['uploadInterconnectionXlsx']));

      const response = await uploadInterconnectionXlsx(
        instance,
        uploadedFiles.map((f) => f.originFileObj),
        {
          categoryId: extractFromProperties('category'),
          costCenterId: extractFromProperties('cost_center'),
          locationId: plantId,
        },
      );

      setSuccessItems(
        response.insertedDocs.map((ins, index) => ({
          id: `${ins?._doc?.name}-${index}`,
          name: ins?._doc?.name,
          file: ins.fileName,
        })),
      );
      setFailItems(
        response.errors.map((error, index) => ({
          id: `${error?.document?.name}-${index}`,
          name: error?.document?.name,
          file: error.fileName,
          cause: error?.message,
        })),
      );
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) =>
        current.filter((item) => item !== 'uploadInterconnectionXlsx'),
      );
    }
  };

  const disableNext = () => {
    if (currentStep === 0) {
      return chosenItem.properties.some((p) => {
        return p.main && p.required && !p.value;
      });
    } else {
      return uploadedFiles.length === 0;
    }
  };

  const openCloseDrawer = (id) => {
    const element = document.getElementById(id);
    if (element?.classList?.contains('open')) {
      element?.classList?.remove('open');
      setOpenedDrawers((current) => current.filter((d) => d != id));
    } else {
      element?.classList?.add('open');
      setOpenedDrawers((current) => current.concat([id]));
    }
  };

  const handleDownload = (category) => {
    const templates = [
      {
        category: 'lv',
        href: 'https://blobstoragebrains01.blob.core.windows.net/dbv2blob/template_cabolv.xlsx?sp=r&st=2025-01-27T20:13:13Z&se=2027-01-28T04:13:13Z&spr=https&sv=2022-11-02&sr=b&sig=VMPFVU1SPAg0ilGzhC0NlURgG1y5Wyeqd91sprIt2tU%3D',
        download: 'template_cabolv.xlsx',
      },
      {
        category: 'mv',
        href: 'https://blobstoragebrains01.blob.core.windows.net/dbv2blob/template_cabomv.xlsx?sp=r&st=2025-01-27T20:13:57Z&se=2027-01-28T04:13:57Z&spr=https&sv=2022-11-02&sr=b&sig=TwqoLjZbBOfQ6D8OA%2Fg7KzE92pNrO5F0jdu1O3KfS1U%3D',
        download: 'template_cabomv.xlsx',
      },
    ];

    const link = document.createElement('a');
    link.href = templates.find((template) =>
      category.toLowerCase().includes(template.category),
    )?.href;
    link.download = templates.find((template) =>
      category.toLowerCase().includes(template.category),
    )?.download;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const steps = [
    <div style={{ minHeight: '10rem' }}>
      <Forms
        departments={departments}
        orgs={orgs}
        manufacturers={manufacturers}
        systems={systems}
        categories={allCategories}
        costcenters={costcenters}
        inputs={chosenItem.properties}
        onInputChange={(event, inputIndex) => {
          setChosenItem((current) => {
            if (current) {
              return updateCurrentEditingItem(current, inputIndex, event);
            }
            return current;
          });
        }}
      />
    </div>,
    <Dragger
      onRemove={async (file) => {}}
      customRequest={async ({ file, onSuccess, onError }) => {
        onSuccess(null, file);
      }}
      accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      multiple
      onChange={(info) => {
        setUploadedFiles(info.fileList);
      }}
      onDrop={(e) => console.log('Dropped files', e.dataTransfer.files)}
    >
      <p className='ant-upload-drag-icon'>
        <InboxOutlined />
      </p>
      <p className='ant-upload-text'>{t('clickOrDrag')}</p>
      <p className='ant-upload-hint'>{t('xlsx_only')}</p>
    </Dragger>,
    drawers,
  ];

  return (
    <div className='uploadSheetModalBody'>
      {contextHolder}
      {steps[currentStep]}
      <div className='uploadSheetModalButtons' style={{ justifyContent: 'space-between' }}>
        <Button
          className='backButton'
          size='large'
          style={{
            width: 'fit-content',
            borderColor: 'black',
            background: 'white',
            color: 'black',
            opacity: chosenItem.properties.find((prop) => prop.key === 'category')?.value ? 1 : 0.5,
          }}
          disabled={
            currentStep === 0
              ? !chosenItem.properties.find((prop) => prop.key === 'category')?.value
              : false
          }
          onClick={() => {
            if (currentStep === 0) {
              handleDownload(
                allCategories.find(
                  (cat) =>
                    cat.id ===
                    chosenItem.properties.find((prop) => prop.key === 'category')?.value?.id,
                ).label,
              );
            } else {
              if (currentStep - 1 === 0) {
                setModalTitle(t('interconnection_details'));
              } else {
                setModalTitle(t('upload_xlsx'));
              }
              setCurrentStep((current) => current - 1);
              setUploadedFiles([]);
            }
          }}
        >
          {currentStep === 0 ? t('download_xlsx_template') : t('back')}
        </Button>

        <Button
          className='nextButton'
          size='large'
          style={{
            width: 'fit-content',
            alignSelf: 'end',
            borderColor: '#1777FF',
            background: '#1777FF',
            color: 'white',
            opacity: disableNext() ? 0.5 : 1,
          }}
          disabled={disableNext()}
          onClick={async () => {
            if (currentStep === 0) {
              setModalTitle(t('upload_xlsx'));
            } else if (currentStep === 1) {
              await handleUploadFiles();
              setModalStyle({
                padding: '2rem',
                gap: '1rem',
                maxHeight: '100%',
              });
              setModalTitle(t('verify_items'));
            } else {
              setModalOpen(false);
              setForceReload(true);
            }
            setCurrentStep((current) => current + 1);
          }}
        >
          {currentStep === steps.length - 1 ? t('refresh') : t('next')}
        </Button>
      </div>
    </div>
  );
};

export default UploadSheetModal;
