import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import Tabs from '../../../../Template/layoutComponents/Tabs';

import HistoryTab from '../../../../Template/layoutComponents/HistoryTab';
import GeneralTab from '../../../../Template/layoutComponents/GeneralTab';

import {
  fetchUserByEmail,
  findUserById,
  getUsers,
  newUser,
  updateUser,
} from '../../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';

import useSettingsState from '../../../atoms/variables';
import useGlobalState from '../../../../Template/atoms/variables';
import { notification } from 'antd';

import './style.css';

const UserDetails = () => {
  const { t } = useTranslation();
  const { userId } = useParams();

  const [notificationApi, contextHolder] = notification.useNotification();
  const { instance } = useMsal();

  const { setPendingApiCalls } = useGlobalState();

  const { viewDetailsCategory, setViewDetailsCategory } = useSettingsState();

  useEffect(() => {
    if (!viewDetailsCategory || !viewDetailsCategory.user_id) {
      triggerApiCalls();
    }
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['findUserById']));

      const response = await getUsers(instance);
      const user = response?.items?.find((o) => o.id === userId || o._id === userId);
      const account = instance.getAccountByHomeId(user.user_id)?.idTokenClaims;
      setViewDetailsCategory({
        ...user,
        label: account?.given_name ? `${account?.given_name} ${account?.family_name}` : user.email,
      });
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'findUserById'));
    }
  };

  return (
    <div id='categoryDetails'>
      <Tabs
        tabs={[
          {
            label: t('general_tab'),
            content: (
              <GeneralTab
                creating={userId === 'newUser'}
                detailsLink={'/settings/users'}
                updateItem={updateUser}
                createItem={newUser}
              />
            ),
            disabled: false,
          },
          {
            label: t('history_tab'),
            content: <HistoryTab id={userId} />,
            disabled: userId === 'newUser',
          },
        ].filter((tab) => tab)}
      />
    </div>
  );
};

export default UserDetails;
