import { useTranslation } from 'react-i18next';
import Table from '../../../../../../../../Template/layoutComponents/Table';
import useGlobalState from '../../../../../../../../Template/atoms/variables';
import { Button, notification, Tooltip } from 'antd';
import { getStageTasks, signTasks } from '../../../../../../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
import { useCallback, useEffect, useState } from 'react';
import ActionCell from './components/ActionsCell';
import { useParams } from 'react-router-dom';
import TableTip from '../../../../../../../../Template/layoutComponents/Table/components/tip';
import { parseIsoDateString } from '../../../../../../../../helpers/misc';
import ReportPage from './components/ReportPage';
import TaskReportService from '../../../../../../../../services/TaskReportService';
import ReportTranslationService from '../../../../../../../../services/ReportTranslationService';
import useTemplateState from '../../../../../../../../Template/atoms/variables';

const TaskReports = ({
  customGetParams,
  showNameOnTable = false,
  stage,
  currentStepLabel,
  setAllTasks,
  ignoreExternalItemsChange = false,
}) => {
  const { t, i18n } = useTranslation();
  const { companyInfo, user } = useTemplateState();
  const { instance } = useMsal();
  const [notificationApi, contextHolder] = notification.useNotification();

  const { assetId, interconnectionId } = useParams();

  const [tasks, setTasks] = useState(null);

  const [selectedTasks, setSelectedTasks] = useState([]);
  const [showButton, setShowButton] = useState(false);

  const [reportOpen, setReportOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [forceReload, setForceReload] = useState(false);

  const { setPendingApiCalls } = useGlobalState();

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat([`getStageTasks-${stage?.pt?.title}`]));

      const responses = await Promise.all([
        getStageTasks(
          instance,
          customGetParams
            ? { ...customGetParams, uniqueValues: true, limit: 10 }
            : {
                stage: currentStepLabel,
                'subject._id': assetId ?? interconnectionId,
                'subject._type': assetId ? 'Asset' : 'Interconnection',
                test: stage?._id,
                uniqueValues: true,
                limit: 10,
              },
        ),
      ]);
      setAllTasks((current) =>
        current.concat(
          responses[0]?.items?.filter(
            (newItem) => !current.some((oldItem) => oldItem._id === newItem._id),
          ) ?? [],
        ),
      );
      setTasks(responses[0]);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) =>
        current.filter((item) => item !== `getStageTasks-${stage?.pt?.title}`),
      );
    }
  };

  const handleSelection = useCallback((selected) => {
    setSelectedTasks((prev) => {
      const hasChanged = JSON.stringify(prev) !== JSON.stringify(selected);
      return hasChanged ? selected : prev; // Evita atualização se não houver mudanças
    });

    setShowButton(selected.length > 0);
  }, []);

  const handleBulkDownload = async (selectedReports) => {
    try {
      setPendingApiCalls((current) => current.concat(['downloadReports']));
      let translatedReports = selectedReports.map((report) =>
        ReportTranslationService.translateReport(report, i18n.language),
      );
      await TaskReportService.build(translatedReports, companyInfo?.logo, true);
    } catch (error) {
      console.error('Error generating PDFs:', error);
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'downloadReports'));
    }
  };

  const handleApproveTasks = async (selectedTasks) => {
    try {
      setPendingApiCalls((current) => current.concat(['approveTasks']));
      let ids = selectedTasks.map((t) => t._id);
      let signedTasks = await signTasks(instance, ids);
      setAllTasks((current) => [
        ...current.filter((task) => !ids.includes(task._id)),
        ...signedTasks,
      ]);
      setForceReload(true);
    } catch (error) {
      console.error('Error approving tasks:', error);
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'approveTasks'));
    }
  };
  const handleRejectTasks = async (selectedTasks) => {
    try {
      setPendingApiCalls((current) => current.concat(['rejectTasks']));
      let ids = selectedTasks.map((t) => t._id);
      let signedTasks = await signTasks(instance, ids, 'reject');
      setAllTasks((current) => [
        ...current.filter((task) => !ids.includes(task._id)),
        ...signedTasks,
      ]);
      setForceReload(true);
    } catch (error) {
      console.error('Error rejecting tasks:', error);
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'rejectTasks'));
    }
  };

  const getDownloadTooltip = () => {
    if (!showButton) return t('select_reports_to_download');
    if (selectedTasks.some((task) => task.status === 'Pending' || task.status === 'Expired'))
      return t('pending_tasks_cannot_be_downloaded');
    return '';
  };

  const getApproveTooltip = () => {
    if (!showButton) return t('select_reports_to_approve');

    if (
      selectedTasks.some(
        (task) =>
          task?.report?.status?.toLowerCase() === 'approved' ||
          task?.report?.status?.toLowerCase() === 'rejected',
      )
    )
      return t('some_reports_are_final');

    if (
      selectedTasks.some(
        (task) =>
          task?.status?.toLowerCase() === 'pending' || task?.status?.toLowerCase() === 'pending',
      )
    )
      return t('some_tasks_are_pending');

    if (!user?.access?.some((access) => ['planner', 'management', 'quality'].includes(access)))
      return t('profile_cannot_approve');

    return '';
  };
  const getRejectTooltip = () => {
    if (!showButton) return t('select_reports_to_repprove');

    if (
      selectedTasks.some(
        (task) =>
          task?.report?.status?.toLowerCase() === 'approved' ||
          task?.report?.status?.toLowerCase() === 'rejected',
      )
    )
      return t('some_reports_are_final');

    if (
      selectedTasks.some(
        (task) =>
          task?.status?.toLowerCase() === 'pending' || task?.status?.toLowerCase() === 'expired',
      )
    )
      return t('some_tasks_are_pending');

    if (!user?.access?.some((access) => ['planner', 'management', 'quality'].includes(access)))
      return t('profile_cannot_reject');

    return '';
  };

  return (
    <>
      {reportOpen && (
        <ReportPage
          setReportOpen={setReportOpen}
          report={selectedReport}
          setReport={setSelectedReport}
          setForceReload={setForceReload}
        />
      )}
      <div className='tasksContent'>
        {contextHolder}
        <div className='tasksContentHeader'>
          <div className='pageTitle'>
            {stage ? `${t('tasks_title')} (${stage?.[i18n.language]?.title})` : ''}
          </div>
          <div className='reportTableButtons'>
            <Tooltip title={getDownloadTooltip()}>
              <Button
                disabled={
                  !showButton ||
                  selectedTasks.some((task) => task.status === 'Pending') ||
                  selectedTasks.some((task) => task.status === 'Expired')
                }
                size='large'
                style={{
                  borderColor: 'black',
                  background: 'white',
                  color: 'black',
                  opacity:
                    showButton &&
                    !selectedTasks.some((task) => task.status === 'Pending') &&
                    !selectedTasks.some((task) => task.status === 'Expired')
                      ? 1
                      : 0.5,
                }}
                onClick={() => handleBulkDownload(selectedTasks)}
              >
                {t('download')}
              </Button>
            </Tooltip>
            <Tooltip title={getApproveTooltip()}>
              <Button
                disabled={
                  !showButton ||
                  selectedTasks.some(
                    (task) =>
                      task?.report?.status?.toLowerCase() === 'n/a' ||
                      task?.report?.status?.toLowerCase() === 'approved' ||
                      task?.report?.status?.toLowerCase() === 'rejected' ||
                      task?.status?.toLowerCase() === 'pending',
                  ) ||
                  !user?.access?.some((access) =>
                    ['planner', 'management', 'quality'].includes(access),
                  )
                }
                size='large'
                style={{
                  borderColor: '#1777FF',
                  background: '#1777FF',
                  color: 'white',
                  opacity:
                    showButton &&
                    !selectedTasks.some(
                      (task) =>
                        task?.report?.status?.toLowerCase() === 'n/a' ||
                        task?.report?.status?.toLowerCase() === 'approved' ||
                        task?.report?.status?.toLowerCase() === 'rejected' ||
                        task?.status?.toLowerCase() === 'pending',
                    ) &&
                    user?.access?.some((access) =>
                      ['planner', 'management', 'quality'].includes(access),
                    )
                      ? 1
                      : 0.5,
                }}
                onClick={() => handleApproveTasks(selectedTasks)}
              >
                {t('approve')}
              </Button>
            </Tooltip>
            <Tooltip title={getRejectTooltip()}>
              <Button
                disabled={
                  !showButton ||
                  selectedTasks.some(
                    (task) =>
                      task?.report?.status?.toLowerCase() === 'n/a' ||
                      task?.report?.status?.toLowerCase() === 'approved' ||
                      task?.report?.status?.toLowerCase() === 'rejected' ||
                      task?.status?.toLowerCase() === 'pending',
                  ) ||
                  !user?.access?.some((access) =>
                    ['planner', 'management', 'quality'].includes(access),
                  )
                }
                size='large'
                style={{
                  borderColor: '#FF4D4F',
                  background: '#FF4D4F',
                  color: 'white',
                  opacity:
                    showButton &&
                    !selectedTasks.some(
                      (task) =>
                        task?.report?.status?.toLowerCase() === 'n/a' ||
                        task?.report?.status?.toLowerCase() === 'approved' ||
                        task?.report?.status?.toLowerCase() === 'rejected' ||
                        task?.status?.toLowerCase() === 'pending',
                    ) &&
                    user?.access?.some((access) =>
                      ['planner', 'management', 'quality'].includes(access),
                    )
                      ? 1
                      : 0.5,
                }}
                onClick={() => handleRejectTasks(selectedTasks)}
              >
                {t('reject')}
              </Button>
            </Tooltip>
          </div>
        </div>
        <Table
          forceReload={forceReload}
          setForceReload={setForceReload}
          // ignoreExternalItemsChange={true}
          handleSelect={handleSelection}
          handleRowClick={null}
          startingSort={{ column: 'createdAt', asc: false }}
          apiCall={async (instance, queryParams) => {
            const response = await getStageTasks(
              instance,
              customGetParams
                ? {
                    ...queryParams,
                    ...customGetParams,
                    uniqueValues: true,
                  }
                : {
                    ...queryParams,
                    stage: currentStepLabel,
                    'subject._id': assetId ?? interconnectionId,
                    'subject._type': assetId ? 'Asset' : 'Interconnection',
                    test: stage?._id,
                    uniqueValues: true,
                  },
            );
            return {
              ...response,
              items: response.items.map((row) => ({
                ...row,
                id: row._id,
                company: row?.employee?.properties?.find((prop) => prop.key === 'company')?.value,
                action: 'placeholder',
              })),
            };
          }}
          items={tasks?.items.map((row) => ({
            ...row,
            id: row._id,
            company: row?.employee?.properties?.find((prop) => prop.key === 'company')?.value,
            action: 'placeholder',
          }))}
          startingTotalItems={tasks?.totalItems ?? 0}
          tableSettings={{
            columnsConfig: {
              subject: showNameOnTable
                ? {
                    label: t('name'),
                    customElement: (item) => {
                      return item.subject.name;
                    },
                  }
                : undefined,
              test: showNameOnTable
                ? {
                    label: t('task'),
                    customElement: (item) => {
                      return item.test[i18n.language]?.title;
                    },
                  }
                : undefined,
              company: {
                label: t('contractor'),
              },
              employee: {
                label: t('executor'),
                customElement: (item) =>
                  item.employee?.properties?.find((prop) => prop.key === 'name')?.value,
              },
              createdAt: {
                label: t('date'),
                replaceValue: (value) => {
                  return {
                    label: parseIsoDateString(new Date(value).toISOString()),
                    id: value,
                  };
                },
              },
              status: {
                label: t('status'),
                customElement: (item) => <TableTip children={item.status ?? item} />,
              },
              report: {
                label: t('report'),
                customElement: (item) => <TableTip children={item?.report?.status ?? item} />,
              },
              action: {
                label: t('action'),
                customElement: (item) => {
                  return (
                    <ActionCell
                      item={item}
                      reportOpen={reportOpen}
                      setReportOpen={setReportOpen}
                      setSelectedReport={setSelectedReport}
                    />
                  );
                },
              },
            },
            sortable: ['createdAt', 'status'],
            listFilter: ['status'],
            searchFilter: [],
            uniqueValues: tasks?.uniqueValues,
          }}
        />
      </div>
    </>
  );
};

export default TaskReports;
